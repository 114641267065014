import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const SecondaryButtonStyled2 = styled(Button)`
  border: none;
  ${({ theme }) => css`
    background: ${theme.colors.primary[13]};
    color: ${theme.colors.primary[3]};

    &:disabled {
      background: ${theme.colors.primary[15]};
    }

    &:active,
    &:hover {
      background: ${theme.colors.primary[11]};
    }
  `}
`;

const SecondaryButton2: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return <SecondaryButtonStyled2 {...rest}>{children}</SecondaryButtonStyled2>;
};

export default SecondaryButton2;
