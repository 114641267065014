import { getTokenFromId, Token } from '@faction-nfts/stencils-smart-contracts';
import {
  Transfer,
  Stencils,
} from '@faction-nfts/stencils-smart-contracts/dist/esm/web3/Stencils';
import { constants } from 'ethers';
import { makeAutoObservable, observable, toJS } from 'mobx';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { Stencils__factory } from '@faction-nfts/stencils-smart-contracts/dist/esm/ethers';

import projectContracts from 'contracts/projects';
import { DEFAULT_CHAIN_ID } from 'constants/chains';
import { INFURA_NETWORK_SOCKETS } from 'constants/chainInfo';
import type { RootStore } from 'stores/RootStore';
import { getProject } from 'api/sdk';
import { Artwork, ProjectTypeType } from 'api/generated/graphql';

const contractAddress = projectContracts.stencils[DEFAULT_CHAIN_ID]
  ?.address as string;
const defaultNode = INFURA_NETWORK_SOCKETS[DEFAULT_CHAIN_ID];
const websocketProvider = new Web3.providers.WebsocketProvider(defaultNode);
const web3 = new Web3(websocketProvider);

type ArtworkData = { tokenId: string; owner: string } & Token;

export type StoredArtwork = ArtworkData;
export type ArtworksFilterAttributes = {
  [key: string]: (number | string)[];
};

export class StencilsStore {
  private rootStore: RootStore;

  public contract: Stencils | null;

  public artworks = observable.map<string, ArtworkData>();

  public searchedArtworkName: string;

  public artworkAttributes: ArtworksFilterAttributes;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.contract = contractAddress
      ? (new web3.eth.Contract(
          Stencils__factory.abi as AbiItem[],
          contractAddress
        ) as unknown as Stencils)
      : null;

    this.searchedArtworkName = '';
    this.artworkAttributes = {
      'Laser-Cut Physical Copy Available': [],
      Palette: [],
      'Grid Layout': [],
      'Cell Type': [],
      'Has Window': [],
      'Has Hatches': [],
      'Solid Count': [],
      'Layer 1 Color': [],
      'Layer 2 Color': [],
      'Layer 3 Color': [],
      'Layer 4 Color': [],
      'Background Color': [],
      'Margin Offset': [],
      Frequency: [],
      'Solids In Margin': [],
      'Window Size': [],
      'Window Position': [],
      'Solid Position 1': [],
      'Solid Position 2': [],
      'Solid Position 3': [],
      'Solid Position 4': [],
    };

    makeAutoObservable(this);
  }

  public init = async () => {
    await this.processExistingArtworks();
    this.subscribeToFutureArtworks();
  };

  get ownedArtworks() {
    const artworks = Array.from(this.artworks.values());
    const account = this.rootStore.web3Store.address;

    return artworks.filter(
      (i) => i.owner.toLowerCase() === account?.toLowerCase()
    );
  }

  get filteredOwnedArtworks() {
    return this.ownedArtworks.filter((item) =>
      item.metadata.name
        .toLowerCase()
        .includes(this.searchedArtworkName.toLowerCase())
    );
  }

  get ownedArtworkCount() {
    return this.ownedArtworks.length;
  }

  private processEvent = (event: Transfer) => {
    const { from, to, tokenId } = event.returnValues;

    if (
      from === constants.AddressZero ||
      (from !== constants.AddressZero && to !== constants.AddressZero)
    ) {
      this.setArtwork(tokenId, to);
    }

    if (to === constants.AddressZero) {
      this.artworks.delete(tokenId);
    }
  };

  private setArtwork = async (tokenId: string, owner: string) => {
    const data = await getTokenFromId(tokenId, {
      imageUri: 'https://stencils-nft.s3.amazonaws.com',
      videoUri: 'videoUri',
      imageExtension: 'webp',
      videoExtension: 'videoExtension',
      includePhysicalCopyInfo: true,
      contract: this.contract,
    });

    // TODO: SDK gives a faulty response
    // data.metadata.generator_url = `https://stencils.faction.art/content/?tokenId=${tokenId}`;

    this.artworks.set(tokenId, { owner, tokenId, ...data });
  };

  private subscribeToFutureArtworks = async () => {
    if (!this.contract) return;

    try {
      const currentBlockNumber = await web3.eth.getBlockNumber();
      const fromBlock = currentBlockNumber - 1000;

      this.contract.events.Transfer({ fromBlock }, (error, transfer) => {
        if (error) {
          throw error;
        }
        if (!!transfer) {
          this.processEvent(transfer);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  private processExistingArtworks = async () => {
    if (!this.contract) return;

    try {
      const project = await getProject(ProjectTypeType.Stencils);
      if (!project) return;
      const sortedArtworks = [...(project?.artworks as Array<Artwork>)].sort(
        (a, b) => Number(a.name?.split('#')[1]) - Number(b.name?.split('#')[1])
      );
      sortedArtworks?.forEach((artwork) => {
        this.setArtwork(
          artwork.tokenId as string,
          artwork.owner?.address as string
        );
      });
    } catch (error) {
      throw error;
    }
  };

  get artworkCount() {
    return Array.from(this.artworks.keys()).length;
  }

  get getArtworks() {
    return Array.from(this.artworks.values());
  }

  public setSearchedArtworkName = (artworkName: string): void => {
    this.searchedArtworkName = artworkName;
  };

  public get filteredArtworks() {
    return this.getArtworks.filter((item) =>
      item.name.toLowerCase().includes(this.searchedArtworkName.toLowerCase())
    );
  }

  public get filteredArtworksByAttributes() {
    const ruleEntries = Object.entries(this.artworkAttributes);

    return this.getArtworks.filter((artwork) => {
      return ruleEntries.every(([attribute, values]) => {
        if (values.length === 0) {
          return true;
        }

        const attr = artwork.metadata.attributes.find(
          (item) => item.trait_type === attribute
        );

        if (!attr) {
          return false;
        }

        if (typeof values[0] === 'string') {
          return values.includes(attr.value?.toString().toLowerCase());
        }

        return attr.value >= values[0] && attr.value <= values[1];
      });
    });
  }

  public get artworksNamesArr() {
    const namesArr = this.getArtworks.map((artwork) => artwork.name);
    return [...new Set(namesArr)];
  }

  public setArtworksAttributes = (
    artworkAttributes: ArtworksFilterAttributes
  ): void => {
    this.artworkAttributes = artworkAttributes;
  };

  get getArtworksAttributes() {
    return this.artworkAttributes;
  }
}
