import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const SecondaryOutlinedButtonStyled = styled(Button)`
  position: relative;
  background: transparent;
  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary[4]};
    color: ${theme.colors.primary[4]};

    &:disabled {
      color: ${theme.colors.primary[21]};
      border-color: ${theme.colors.primary[21]};
    }

    &:active {
      background: ${theme.colors.primary[1]};
      color: ${theme.colors.primary[9]};
    }

    &:hover {
      background: ${theme.colors.primary[6]};
    }

    &:focus {
      background: ${theme.colors.primary[6]};
    }
  `}
`;

const SecondaryOutlinedButton: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <SecondaryOutlinedButtonStyled {...rest}>
      {children}
    </SecondaryOutlinedButtonStyled>
  );
};

export default SecondaryOutlinedButton;
