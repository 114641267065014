import { apolloClient } from 'api/apolloClient';
import {
  UserCreateInput,
  UserUpdateInput,
  ProjectTypeType,
  Project,
  User,
} from 'api/generated/graphql';

import {
  GET_PROJECT,
  GET_ARTWORK,
  GET_USER_NONCE,
  GET_USER_PROJECTS,
  GET_USER_COLLECTED,
  GET_USER,
  GET_DAO_MEMBERS,
  GET_HOME_PROJECTS,
  GET_FEATURED_PROJECTS,
} from './queries';
import { CREATE_USER, UPDATE_USER } from './mutations';

const getProject = async (projectType: ProjectTypeType): Promise<Project> => {
  const res = await apolloClient.query({
    query: GET_PROJECT,
    variables: {
      projectType,
    },
  });

  return res.data?.project;
};

const getFeaturedProjects = async (): Promise<Project[]> => {
  const res = await apolloClient.query({
    query: GET_FEATURED_PROJECTS,
  });

  return [...res.data?.projects].sort(
    (a: Project, b: Project) => a.homepageWeight! - b.homepageWeight!
  );
};

const getHomeProjects = async (): Promise<Project[]> => {
  const res = await apolloClient.query({
    query: GET_HOME_PROJECTS,
  });

  const projects: Project[] = [...res.data?.projects];

  return projects.sort((a, b) => a.homepageWeight! - b.homepageWeight!);
};

const getUser = async (walletAddress: string): Promise<User> => {
  const res = await apolloClient.query({
    query: GET_USER,
    variables: {
      address: walletAddress,
    },
    fetchPolicy: 'no-cache',
  });

  return res.data?.user;
};

const getIntendedNonce = async (address: string): Promise<number | null> => {
  try {
    let nonce: null | number = null;
    const nonceResult = await apolloClient.query({
      query: GET_USER_NONCE,
      variables: { address },
    });

    if (nonceResult.data?.user) {
      nonce = nonceResult.data.user.nonce + 1;
    }

    return nonce;
  } catch (e) {
    console.error(e);
    throw new Error('Error getting nonce, generally a connectivity issue');
  }
};

const createUser = async (data: UserCreateInput): Promise<User> => {
  try {
    const res = await apolloClient.mutate({
      mutation: CREATE_USER,
      variables: data,
    });

    if (res.errors) {
      console.error(res.errors);
      throw new Error();
    }

    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error('Failed creating user');
  }
};

const updateUser = async (data: UserUpdateInput): Promise<User> => {
  try {
    const res = await apolloClient.mutate({
      mutation: UPDATE_USER,
      variables: data,
    });
    if (res.errors) {
      console.error(res.errors);
      throw new Error();
    }

    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error('Failed updating user');
  }
};

const getArtwork = async (
  projectType: ProjectTypeType,
  tokenId: string
): Promise<Project> => {
  const res = await apolloClient.query({
    query: GET_ARTWORK,
    variables: {
      projectType,
      tokenId,
    },
  });

  return res.data.project;
};

const getUserProjects = async (walletAddress: string): Promise<User> => {
  const res = await apolloClient.query({
    query: GET_USER_PROJECTS,
    variables: {
      walletAddress,
    },
  });

  return res.data.user;
};

const getUserCollected = async (walletAddress: string): Promise<User> => {
  const res = await apolloClient.query({
    query: GET_USER_COLLECTED,
    variables: {
      walletAddress,
    },
  });

  return res.data.user;
};

const getDaoMembers = async (): Promise<User[]> => {
  const res = await apolloClient.query({
    query: GET_DAO_MEMBERS,
    variables: {
      isMember: true,
    },
  });

  return [
    ...res.data.users,
    {
      name: 'Stefano Contiero',
      title: 'generative artist',
      twitter: 'https://twitter.com/stefan_contiero',
      instagram: 'https://www.instagram.com/stefan_contiero/',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1605630466664570881/_aIlH8JV_400x400.jpg',
      },
    },
    {
      name: 'Serena Tabacchi',
      title: 'MoCDA founder',
      twitter: 'https://twitter.com/SerenaTabacchi',
      instagram: 'https://www.instagram.com/serena.tabacchi/',
      profileImage: {
        publicUrl:
          'https://images.squarespace-cdn.com/content/v1/5b998b2ada02bc70d12d14d7/1575087864450-MBPVLPGY4S8G4RRTA1KY/Screen+Shot+2019-11-29+at+11.23.41+PM.png?format=750w',
      },
    },
    {
      name: 'Pierre Pauze',
      title: 'contemporary video artist',
      twitter: 'https://twitter.com/PauzePierre',
      instagram: 'https://www.instagram.com/pierrepauze/',
      profileImage: {
        publicUrl:
          'https://www.citedesartsparis.net/media/cia/183726-capture_d_e_cran_2019-01-05_a_18.00.32.png',
      },
    },
    {
      name: 'Emile Dubié',
      title: 'Digital Art Collector, XDEFI Wallet co-founder',
      twitter: 'https://twitter.com/goitbykojiro',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1635304763938971649/3i8_7h-l_400x400.jpg',
      },
    },
    {
      name: 'Marc Weinstein',
      title: 'digital art collector, Mechanism Capital',
      twitter: 'https://twitter.com/WarcMeinstein',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1586020799265345539/y_xJTcGC_400x400.jpg',
      },
    },
    {
      name: 'Nicolas Bourriaud',
      title:
        'contemporary art critic, curator, co-founder Palais de Tokyo, ex.Tate Britain',
      twitter: 'https://twitter.com/NBourriaud',
      instagram: 'https://www.instagram.com/bourriaudnicolas/',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/624124179386867712/VZ7OSl3O_400x400.jpg',
      },
    },
    {
      name: 'Hugo Pouchard',
      title: "DAO admin, ex. Christie's, ex artsy.net",
      twitter: 'https://twitter.com/hugopouch',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1539219046436155393/_M2ehVrn_400x400.jpg',
      },
    },
    {
      name: 'Michael de Luca',
      title: 'senior solidity developer, ETH foundation',
      twitter: 'https://twitter.com/de_luca_mike',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1321526575867842563/sU0Uh1jd_400x400.jpg',
      },
    },
    {
      name: 'David Phan',
      title: 'tech lead, senior solidity developer',
      twitter: 'https://twitter.com/DefragProg',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1363047391335694337/hwYy04He_400x400.jpg',
      },
    },
    {
      name: 'DCinvestor',
      title: 'digital art collector',
      twitter: 'https://twitter.com/iamDCinvestor',
      instagram: 'https://www.instagram.com/iamdcinvestor/',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1548726894417305601/Ko19bO_Q_400x400.png',
      },
    },
    {
      name: 'Nicolas Niwin',
      twitter: 'https://twitter.com/NiwinEth',
      title: 'artblocks project manager & digital art collector',
      profileImage: {
        publicUrl:
          'https://pbs.twimg.com/profile_images/1612562099036688386/Y6xQ29hf_400x400.jpg',
      },
    },
  ].map((val) => ({ ...val, title: val.title.toLowerCase() }));
};

export {
  getProject,
  createUser,
  updateUser,
  getIntendedNonce,
  getArtwork,
  getUserProjects,
  getHomeProjects,
  getUserCollected,
  getUser,
  getDaoMembers,
  getFeaturedProjects,
};
