/* eslint-disable @typescript-eslint/lines-between-class-members */
import { action, makeAutoObservable, reaction, when, observable } from 'mobx';
import type {
  ConnectOptions,
  ConnectOptionsString,
  WalletState,
} from '@web3-onboard/core/dist/types.d';
import { getAddress } from '@ethersproject/address';
import { Web3Provider } from '@ethersproject/providers';
import { hexlify } from '@ethersproject/bytes';

import type AbstractOnboard from './walletConnect';

class Web3Store {
  private _onboardLoaded = false;
  private _subscriptions: (() => void)[] = [];
  protected _primaryWallet: undefined | WalletState = undefined;
  protected _onboard: undefined | typeof AbstractOnboard = undefined;
  protected observables = {
    _primaryWallet: observable,
    _getOnboard: action,
  };

  constructor() {
    makeAutoObservable(this);

    this.loadSubscriptions();
    this.loadPreviousWallet();
  }

  async awaitWeb3StoreInitialization() {
    return when(() => this._onboardLoaded);
  }

  async _getOnboard() {
    if (!this._onboard) {
      this._onboard = (await import('./walletConnect')).default;
      this._onboardLoaded = true;
    }

    return this._onboard;
  }

  get address() {
    const address = this._primaryWallet?.accounts[0].address;

    return address && getAddress(address);
  }

  get chainId() {
    return this._primaryWallet?.chains[0].id;
  }

  get provider() {
    return (
      this._primaryWallet && new Web3Provider(this._primaryWallet.provider)
    );
  }

  get isActive() {
    return !!this._primaryWallet?.accounts[0].address;
  }

  async connect(connectOptions?: ConnectOptions | ConnectOptionsString) {
    const onboard = await this._getOnboard();

    return onboard.connectWallet(connectOptions);
  }

  async disconnect() {
    const onboard = await this._getOnboard();

    if (this._primaryWallet) {
      await onboard.disconnectWallet({ label: this._primaryWallet.label });
    }
  }

  async switchChain(chainId: number) {
    const onboard = await this._getOnboard();

    return onboard.setChain({
      chainId: hexlify(chainId),
      chainNamespace: 'evm',
    });
  }

  destroy() {
    for (const unsubscribe of this._subscriptions) {
      unsubscribe();
    }
  }

  private async loadSubscriptions() {
    this._subscriptions = [
      await this.subscribeToWallets(),
      await this.saveConnectedWallet(),
    ];
  }

  private loadPreviousWallet() {
    const storedConnectedWallet = localStorage.getItem('connectedWallet');

    if (storedConnectedWallet) {
      const connectOptions = {
        autoSelect: {
          label: storedConnectedWallet,
          disableModals: true,
        },
      } as ConnectOptions;

      return this.connect(connectOptions);
    }
  }

  private async subscribeToWallets() {
    await when(() => this._onboardLoaded);

    const onboard = await this._getOnboard();
    const { unsubscribe } = onboard.state
      .select('wallets')
      .subscribe((wallets) => {
        this._primaryWallet = wallets[0];
      });

    return () => unsubscribe();
  }

  private saveConnectedWallet() {
    return reaction(
      () => this._primaryWallet,
      (connectedWallet) => {
        localStorage.setItem('connectedWallet', connectedWallet?.label || '');
      }
    );
  }
}

export default Web3Store;
