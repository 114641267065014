import { NavLink } from 'react-router-dom';

import { LINKS } from 'router/config';
import { BorderlessButton } from 'modules/components/Button';

export const Navigation = (): JSX.Element => (
  <>
    <NavLink to={LINKS.ABOUT}>
      <BorderlessButton fixed={false}>About us</BorderlessButton>
    </NavLink>

    <a href="mailto: info@faction.art">
      <BorderlessButton fixed={false}>Contact us</BorderlessButton>
    </a>

    <NavLink to={LINKS.PRIVACY}>
      <BorderlessButton fixed={false}>Privacy</BorderlessButton>
    </NavLink>

    <NavLink to={LINKS.TERMS}>
      <BorderlessButton fixed={false}>Terms & Conditions</BorderlessButton>
    </NavLink>
  </>
);
