import { useEffect, useState } from 'react';

import { Cloudinary } from '@cloudinary/url-gen';
import { fill } from '@cloudinary/url-gen/actions/resize';
import styled from 'styled-components/macro';

import Spinner from 'modules/components/Spinner/Spinner';

const LoaderWrapperStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SmartImageProps {
  url: string;
  placeholder?: string;
  width?: number;
  height?: number;
  className?: string;
  objectFit?: boolean;
  onClick?: () => void;
  onError?: () => void;
}

const cld = new Cloudinary({
  cloud: {
    cloudName: 'faction',
  },
});

const SmartImage = (props: SmartImageProps): JSX.Element | null => {
  const {
    url,
    width,
    height,
    placeholder,
    className,
    onClick,
    onError,
    objectFit,
  } = props;

  const [newImg, setImg] = useState('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!url.includes('cloudinary')) {
      return setImg(url);
    }

    const res = new RegExp(
      'https?:\\/\\/(?<host>[^\\/]+)\\/(?<cloudname>[^\\/]+)\\/[^\\/]+\\/(?<resource_type>[^\\/]+)(?:\\/[^\\/,]*,[^\\/]*)?\\/(?<id>.*)',
      'g'
    ).exec(url);
    const id = res?.groups?.id.split('.').slice(0, -1).join('.');
    const myImage = cld.image(id);
    myImage.format('auto').quality('auto');

    if (height && width) {
      myImage.resize(fill().width(width).height(height));
    }

    setImg(myImage.toURL());
  }, [url]);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleError = () => {
    setLoading(false);
    if (placeholder) {
      setImg(placeholder);
    }

    if (onError) {
      onError();
    }
  };

  return (
    <>
      {isLoading && (
        <LoaderWrapperStyled>
          <Spinner size={50} />
        </LoaderWrapperStyled>
      )}

      <img
        key={newImg}
        style={{
          visibility: isLoading ? 'hidden' : 'visible',
          objectFit: objectFit ? 'cover' : 'fill',
        }}
        src={newImg || placeholder}
        width={width || '100%'}
        height={height || '100%'}
        // crossOrigin="anonymous"
        decoding={'async'}
        className={className}
        onClick={onClick}
        onLoad={handleLoad}
        onError={handleError}
      />
    </>
  );
};

export default SmartImage;
