import { SupportedChainId } from './chains';

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const INFURA_NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://rpc.ankr.com/eth`,
  [SupportedChainId.GOERLI]: `https://rpc.ankr.com/eth_goerli`,
  [SupportedChainId.GANACHE]: 'http://0.0.0.0:8545',
};

export const INFURA_NETWORK_SOCKETS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `wss://mainnet.infura.io/ws/v3/ca72c7225fd346ab9f024de9f005a0b0`,
  [SupportedChainId.GOERLI]: `wss://goerli.infura.io/ws/v3/ca72c7225fd346ab9f024de9f005a0b0`,
  [SupportedChainId.GANACHE]: 'ws://localhost:8545',
};
