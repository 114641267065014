import { useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import GridList from 'react-gridlist';

import { NftCard } from 'modules/components/NftCard';
import { useRootStore } from 'providers/RootStoreProvider';
import { LINKS } from 'router/config';
import { ProjectTypeType } from 'api/generated/graphql';
import PH_MOL from 'assets/images/PH_MOL.jpg';
import { Artwork } from 'stores/XSublimatio/XSublimatioStore';
import ErrorTemplate from 'modules/components/ErrorTemplate';
import { TabLoader } from 'modules/app/App';

const CARD_WIDTH = 330;
const CARD_HEIGHT = 475;
const GRID_GAP = 20;

function getGridGap(elementWidth: number, windowHeight: number) {
  return GRID_GAP;
}

function getColumnCount(elementWidth: number, gridGap: number) {
  if (window.innerWidth < 480) {
    return 1;
  }

  if (window.innerWidth < 992) {
    return 2;
  }

  if (window.innerWidth < 1440) {
    return 3;
  }

  if (window.innerWidth < 2050) {
    return 4;
  }

  return 5;
}

function getWindowMargin(windowHeight: number) {
  return Math.round(windowHeight * 1.5);
}

function getItemData(artwork: Artwork) {
  return {
    key: artwork.tokenId,
    height: CARD_HEIGHT,
  };
}

const NftCatalogContent = observer((): JSX.Element => {
  const { xsublimatioStore } = useRootStore();
  const params = useParams<{ projectType: ProjectTypeType }>();

  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, 500);
  }, []);

  const { filteredArtworksByAttributes } = xsublimatioStore;

  if (filteredArtworksByAttributes.length === 0) {
    return (
      <ErrorTemplate
        type={'empty_data'}
        text={'No artworks'}
        link={{
          url: LINKS.PROJECT('xsublimatio').CREATE_DRUG.ALL,
          text: 'Collect artworks',
        }}
      />
    );
  }

  return isShowing ? (
    <GridList
      items={filteredArtworksByAttributes}
      getGridGap={getGridGap}
      getColumnCount={getColumnCount}
      getWindowMargin={getWindowMargin}
      getItemData={getItemData}
      fixedColumnWidth={CARD_WIDTH}
      renderItem={(artwork) => {
        const href = LINKS.ARTWORK(
          params?.projectType || '',
          artwork?.tokenId
        ).INDEX;
        return (
          <NftCard
            imageUrl={artwork.thumbnail}
            placeholder={PH_MOL}
            showButton={false}
            name={artwork?.data?.name || 'Unknown'}
            artistName={artwork?.artist?.name || 'Unknown'}
            href={href}
            tokenId={artwork.tokenId}
          />
        );
      }}
    />
  ) : (
    <TabLoader />
  );
});

export default NftCatalogContent;
