import * as React from 'react';
import { useState } from 'react';

import styled from 'styled-components/macro';
import { Composition } from 'atomic-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { PrimaryButton } from 'modules/components/Button';
import { Icon, TIconName } from 'modules/components/Icon';
import { LINKS } from 'router/config';
import { useRootStore } from 'providers/RootStoreProvider';

interface INavItem {
  title: string;
  icon: TIconName;
  children?: INavItem[];
  link?: string;
}

const nav = (walletAddress?: string): INavItem[] => [
  {
    title: 'Home',
    icon: 'home',
    link: LINKS.HOME,
  },
  {
    title: 'Featured',
    icon: 'fire',
    link: LINKS.FEATURED.INDEX,
  },
  {
    title: 'DAO',
    icon: 'bank',
    link: LINKS.DAO.MEMBERS,
  },
  {
    title: 'Profile',
    icon: 'user-filled',
    children: [
      {
        title: 'Collected',
        icon: 'star',
        link: LINKS.USER(walletAddress).COLLECTED,
      },
      // {
      //   title: 'Favorites',
      //   icon: 'heart',
      //   link: LINKS.USER.FAVORITES,
      // },
      // {
      //   title: 'Projects',
      //   icon: 'palette',
      //   link: LINKS.USER(walletAddress).PROJECTS,
      // },
      // {
      //   title: 'Settings',
      //   icon: 'settings',
      //   link: LINKS.USER().SETTINGS,
      // },
      // {
      //   title: 'Log out',
      //   icon: 'power-button',
      // },
      // {
      //   title: 'Dark mode',
      //   icon: 'moon',
      // },
    ],
  },
  {
    title: 'About us',
    icon: 'info-circle-filled',
    link: LINKS.ABOUT,
  },
  // {
  //   title: 'Resources',
  //   icon: 'book',
  //   children: [
  //     {
  //       title: 'About us',
  //       icon: 'info-circle-filled',
  //       link: LINKS.ABOUT,
  //     },
  //     {
  //       title: 'Contact us',
  //       icon: 'envelope',
  //     },
  //     {
  //       title: 'Terms of service',
  //       icon: 'document',
  //     },
  //     {
  //       title: 'Guidelines',
  //       icon: 'checked-box',
  //     },
  //     {
  //       title: 'Submit you Art',
  //       icon: 'eye',
  //     },
  //   ],
  // },
];

const NavigationListStyled = styled.ul`
  position: relative;
  padding: 0 15px;
`;

const NavigationListItemStyled = styled.li`
  position: relative;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.primary[4]};
  color: ${(props) => props.theme.colors.primary[4]};
  cursor: pointer;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.colors.primary[1]};
  }
`;

const TextStyled = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
`;

const UserMenuMobileStyled = styled.div`
  position: relative;
`;

const UserButtonStyled = styled.button<{ open: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 24px;
    height: 3px;
    background-color: ${(props) => props.theme.colors.primary[3]};
    border-radius: 10px;
    transition: all 0.15s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

const NavIconWrapperStyled = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
`;

const NavContainerStyled = styled.div`
  position: fixed;
  top: 82px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.primary[9]};
  border-top: 1px solid ${(props) => props.theme.colors.primary[4]};
  overflow: hidden;
`;

const SocialLinkStyled = styled.a`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary[4]};
  height: 100%;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const SocialLinksContainerStyled = styled.div`
  position: relative;
  height: 100%;
  border-top: 1px solid ${(props) => props.theme.colors.primary[4]};
`;

const BackWrapperStyled = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.primary[5]};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
`;

const LeftArrowIconStyled = styled.div`
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  color: ${(props) => props.theme.colors.primary[9]};
  svg {
    width: 24px;
    height: 24px;
  }
`;

const BackTextStyled = styled.span`
  font-size: 18px;
  line-height: 23px;
  color: ${(props) => props.theme.colors.primary[9]};
`;

const NavWrapperStyled = styled.div``;
const NavTitleStyled = styled.div`
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary[13]};
  border-bottom: 0.5px solid ${(props) => props.theme.colors.primary[4]};
  font-size: 20px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.primary[4]};
`;

interface NavListProps {
  data: INavItem[];
  onClickItem?: (item: INavItem) => void;
}

const NavList = (props: NavListProps): JSX.Element => {
  const { data, onClickItem } = props;

  return (
    <NavigationListStyled>
      {data.map((item: INavItem) => {
        return (
          <NavigationListItemStyled
            key={item.title}
            onClick={() => onClickItem && onClickItem(item)}
          >
            <TextStyled>{item.title}</TextStyled>
            <NavIconWrapperStyled>
              <Icon name={item.icon} />
            </NavIconWrapperStyled>
          </NavigationListItemStyled>
        );
      })}
    </NavigationListStyled>
  );
};

const UserMenuMobile = observer((): JSX.Element => {
  const [isOpen, setOpen] = React.useState(false);
  const [displayChildren, setDisplayChildren] = useState<INavItem | any>({});
  const navigate = useNavigate();
  const { web3Store } = useRootStore();
  const params = useParams<{ walletAddress: string }>();

  const handleToggle = () => {
    setOpen(!isOpen);
    if (!isOpen) {
      setDisplayChildren({});
    }
  };

  const handleBack = () => {
    setDisplayChildren({});
  };

  const handleNavigate = (item: INavItem) => {
    if (item?.link) {
      navigate(item.link);
      handleToggle();
    }
  };

  const handleOpenWalletModal = () => {
    web3Store.connect();
    setOpen(false);
  };

  const handleLogout = () => {
    setOpen(false);
    web3Store.disconnect();
  };

  const isNextLevel = Object.keys(displayChildren).length;

  return (
    <UserMenuMobileStyled>
      <UserButtonStyled onClick={handleToggle} open={isOpen}>
        <div />
        <div />
        <div />
      </UserButtonStyled>
      {isOpen && (
        <Composition
          as={NavContainerStyled}
          areas={`
            list
            footer
        `}
          templateRows={'auto 1fr'}
        >
          {(Areas) => {
            return (
              <>
                <Areas.List>
                  <>
                    {isNextLevel ? (
                      Object.keys(displayChildren).map((key) => {
                        const item = displayChildren[key];
                        return (
                          <NavWrapperStyled>
                            <NavTitleStyled>{item.title}</NavTitleStyled>
                            <NavList
                              onClickItem={handleNavigate}
                              data={item.children}
                            />
                          </NavWrapperStyled>
                        );
                      })
                    ) : (
                      <NavList
                        data={nav(params?.walletAddress || web3Store.address)}
                        onClickItem={(item) => {
                          if (item.children) {
                            setDisplayChildren({
                              ...displayChildren,
                              [item.title]: item,
                            });
                          }

                          handleNavigate(item);
                        }}
                      />
                    )}
                  </>
                </Areas.List>

                <Areas.Footer align={'end'}>
                  {isNextLevel ? (
                    <BackWrapperStyled onClick={handleBack}>
                      <LeftArrowIconStyled>
                        <Icon name={'long-left-arrow'} />
                      </LeftArrowIconStyled>
                      <BackTextStyled>BACK</BackTextStyled>
                    </BackWrapperStyled>
                  ) : (
                    <Composition
                      areas={`
                      connect
                      socials
                  `}
                      height={'100%'}
                      templateRows={'minmax(135px,1fr) 115px'}
                    >
                      {(AreasFooter) => {
                        return (
                          <>
                            <AreasFooter.Connect
                              justify={'center'}
                              align={'center'}
                            >
                              {!web3Store.isActive ? (
                                <PrimaryButton
                                  size={'l'}
                                  textTransform={'capitalize'}
                                  onClick={handleOpenWalletModal}
                                >
                                  {'Connect wallet'}
                                </PrimaryButton>
                              ) : (
                                <PrimaryButton
                                  size={'l'}
                                  textTransform={'capitalize'}
                                  onClick={handleLogout}
                                >
                                  {'Log out'}
                                </PrimaryButton>
                              )}
                            </AreasFooter.Connect>

                            <AreasFooter.Socials>
                              <Composition
                                as={SocialLinksContainerStyled}
                                templateCols={'repeat(3, 1fr)'}
                              >
                                <SocialLinkStyled>
                                  <Icon
                                    name={'twitter'}
                                    href={'https://twitter.com/faction_art'}
                                    target={'_blank'}
                                  />
                                </SocialLinkStyled>
                                <SocialLinkStyled>
                                  <Icon
                                    name={'instagram'}
                                    href={
                                      'https://www.instagram.com/faction_nft/'
                                    }
                                    target={'_blank'}
                                  />
                                </SocialLinkStyled>
                                <SocialLinkStyled>
                                  <Icon
                                    name={'discord'}
                                    href={'https://discord.gg/MpvH5U5Xhz'}
                                    target={'_blank'}
                                  />
                                </SocialLinkStyled>
                              </Composition>
                            </AreasFooter.Socials>
                          </>
                        );
                      }}
                    </Composition>
                  )}
                </Areas.Footer>
              </>
            );
          }}
        </Composition>
      )}
    </UserMenuMobileStyled>
  );
});

export default UserMenuMobile;
