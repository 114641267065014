import styled from 'styled-components/macro';
import { query } from 'atomic-layout';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import { LINKS } from 'router/config';
import { PrimaryButton } from 'modules/components/Button';
import { getHomeProjects } from 'api/sdk';
import { CountDownTimer } from 'modules/components/CountDownTimer';
import { useRootStore } from 'providers/RootStoreProvider';

import {
  Card,
  ItemId,
  ItemImage,
  Item,
  ContentContainer,
  TitleSection,
  ActionBar,
} from './styles';

const ActionBarWrapper = styled(ActionBar)`
  justify-content: center;
  width: 100%;
  margin-top: 70px;
  padding-top: 50px;
  border-top: solid 0.5px rgba(0, 0, 0, 0.5);
`;

const ItemImageWrapper = styled(ItemImage)`
  height: 280px;
  width: 280px;

  @media ${query({ from: 'sm' })} {
    height: 350px;
    width: 350px;
  }
`;

const ContentContainerWrapper = styled(ContentContainer)`
  align-items: center;
  padding-top: 30px;
`;

const InfoWrapperStyled = styled.div`
  color: ${(props) => props.theme.colors.primary[3]};
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  p {
    margin-bottom: 15px;
  }

  margin-bottom: 90px;
`;
const AnimationWrapperStyled = styled.div`
  img {
    max-width: 175px;
    height: auto;
  }
`;

export const DrugCreatedCard = observer((): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ projectType: string }>();
  const { xsublimatioController } = useRootStore();
  const {
    data: seconds,
    error,
    isLoading,
  } = useQuery(
    ['drug-create', xsublimatioController.tokenId],
    async () => {
      const res = await axios.get(
        `https://xsublimatio.faction.art/api/estimate/${xsublimatioController.tokenId}`
      );
      return res.data.estimate;
    },
    {
      onError: (err) => {
        // console.log(err);
        toast.error('Something went wrong with getting the time left');
      },
    }
  );

  const goToDrugs = () => {
    navigate(LINKS.PROJECT(params.projectType || '').MY_BAG.VISUAL.DRUGS);
  };

  return (
    <Card>
      <TitleSection>We are cooking your drug</TitleSection>
      <ContentContainerWrapper>
        <InfoWrapperStyled>
          <p>
            Ready in {isLoading ? '...' : <CountDownTimer seconds={seconds} />}
          </p>
          <p>You’ll be notified when your drug is ready</p>
        </InfoWrapperStyled>

        <AnimationWrapperStyled>
          <img src={'/video/drug_animation.gif'} />
        </AnimationWrapperStyled>

        <ActionBarWrapper>
          <PrimaryButton onClick={goToDrugs}>CLOSE</PrimaryButton>
        </ActionBarWrapper>
      </ContentContainerWrapper>
    </Card>
  );
});
