import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const SecondaryButtonStyled3 = styled(Button)`
  border: none;
  ${({ theme }) => css`
    background: ${theme.colors.primary[13]};
    color: ${theme.colors.primary[17]};

    &:disabled {
      background: ${theme.colors.primary[13]};
    }

    &:hover {
      color: ${theme.colors.primary[9]};
      background: ${theme.colors.primary[18]};
    }

    &:active {
      background: ${theme.colors.primary[17]};
      color: ${theme.colors.primary[9]};
    }

    &:focus {
      background: ${theme.colors.primary[13]};
      color: ${theme.colors.primary[17]};
    }
  `}
`;

const SecondaryButton3: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return <SecondaryButtonStyled3 {...rest}>{children}</SecondaryButtonStyled3>;
};

export default SecondaryButton3;
