import { useEffect, useRef } from 'react';

import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components/macro';
import { AnimatePresence } from 'framer-motion';
import { BulletList } from 'react-content-loader';
// import { useConnectWallet, useWallets } from '@web3-onboard/react';
import ResizeObserver from 'resize-observer-polyfill';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { WalletState } from '@web3-onboard/core';

import Spinner from 'modules/components/Spinner/Spinner';
import { Lazy } from 'utils/lazy';
import { PATHS } from 'router/config';
import Layout from 'modules/layout';
import { Theme } from 'theme/Theme';
import { useRootStore } from 'providers/RootStoreProvider';
import ArtworkVideoPage from 'pages/ArtWork/ArtworkVideo';
import NftCatalogContent from 'pages/Project/components/Laboratory/tabs/NftCatalog/NftCatalogContent';
import VisualGridCards from 'pages/Project/components/Laboratory/tabs/MyBag/VisualGridCards';
import CreateDrugContent from 'pages/Project/components/Laboratory/tabs/CreateDrug/CreateDrugContent';
//test
(window as any).ResizeObserver = ResizeObserver;

const PageLoaderWrapperStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
`;

const TabLoaderWrapperStyled = styled.div`
  position: relative;
  display: flex;
  height: 500px;
  max-width: 1140px;
  margin: 0 auto;
`;

export const PageLoader = () => {
  return (
    <PageLoaderWrapperStyled>
      <Spinner />
    </PageLoaderWrapperStyled>
  );
};

export const TabLoader = () => {
  return (
    <TabLoaderWrapperStyled>
      <BulletList />
    </TabLoaderWrapperStyled>
  );
};

const Home = Lazy(import('pages/Home'), <PageLoader />);
const NotFound = Lazy(import('pages/NotFound'), <PageLoader />);
const User = Lazy(import('pages/User'), <PageLoader />);
const Dao = Lazy(import('pages/Dao'), <PageLoader />);
const ArtWork = Lazy(import('pages/ArtWork'), <PageLoader />);
const Featured = Lazy(import('pages/Featured'), <PageLoader />);

const Project = Lazy(import('pages/Project'), <PageLoader />);

const Stencils = Lazy(import('pages/StencilsProject'), <PageLoader />);
const StencilsNftCatalog = Lazy(
  import('pages/StencilsProject/components/Artworks/tabs/NftCatalog'),
  <PageLoader />
);
const StencilsMyBag = Lazy(
  import('pages/StencilsProject/components/Artworks/tabs/MyBag'),
  <PageLoader />
);

const About = Lazy(import('pages/About'), <PageLoader />);

const PrivacyPolicy = Lazy(import('pages/PrivacyPolicy'), <PageLoader />);
const Terms = Lazy(import('pages/Terms'), <PageLoader />);

// Featured tabs
const FeaturedLive = Lazy(import('pages/Featured/Live'), <TabLoader />);
const FeaturedUpcoming = Lazy(import('pages/Featured/Upcoming'), <TabLoader />);
const FeaturedAll = Lazy(import('pages/Featured/All'), <TabLoader />);

// PROJECT Tabs
const CreateDrug = Lazy(
  import('pages/Project/components/Laboratory/tabs/CreateDrug/CreateDrug'),
  <TabLoader />
);

// const CreateDrugContent = Lazy(
//   import(
//     'pages/Project/components/Laboratory/tabs/CreateDrug/CreateDrugContent'
//   ),
//   <TabLoader />
// );

const NftCatalog = Lazy(
  import('pages/Project/components/Laboratory/tabs/NftCatalog'),
  <TabLoader />
);

// const NftCatalogContent = Lazy(
//   import(
//     'pages/Project/components/Laboratory/tabs/NftCatalog/NftCatalogContent'
//   ),
//   <TabLoader />
// );

const NftCatalogText = Lazy(
  import('pages/Project/components/Laboratory/tabs/NftCatalog/TextView'),
  <TabLoader />
);

const MyBag = Lazy(
  import('pages/Project/components/Laboratory/tabs/MyBag'),
  <TabLoader />
);

const TextView = Lazy(
  import('pages/Project/components/Laboratory/tabs/MyBag/TextView'),
  <TabLoader />
);

const VisualView = Lazy(
  import('pages/Project/components/Laboratory/tabs/MyBag/VisualView'),
  <TabLoader />
);

// const VisualGridCards = Lazy(
//   import('pages/Project/components/Laboratory/tabs/MyBag/VisualGridCards'),
//   <TabLoader />
// );

const Recipes = Lazy(
  import('pages/Project/components/Laboratory/tabs/Recipes'),
  <TabLoader />
);

const DrugView = Lazy(
  import('pages/Project/components/Laboratory/tabs/Recipes/DrugView'),
  <TabLoader />
);

const MoleculeView = Lazy(
  import('pages/Project/components/Laboratory/tabs/Recipes/MoleculeView'),
  <TabLoader />
);

// DAO Tabs
const Manifesto = Lazy(
  import('pages/Dao/Manifesto/ManifestoModule'),
  <TabLoader />
);
const Members = Lazy(import('pages/Dao/Members/MembersModule'), <TabLoader />);

const Proposals = Lazy(
  import('pages/Dao/Proposals/ProposalsModule'),
  <TabLoader />
);
const Metrics = Lazy(import('pages/Dao/Metrics/MetricsModule'), <TabLoader />);
const Diagram = Lazy(import('pages/Dao/Diagram/DiagramModule'), <TabLoader />);

// USER Tabs
const Collected = Lazy(
  import('pages/User/Collected/CollectedModule'),
  <TabLoader />
);
const Favorites = Lazy(
  import('pages/User/Favorites/FavoritesModule'),
  <TabLoader />
);
const Projects = Lazy(
  import('pages/User/Projects/ProjectModule'),
  <TabLoader />
);

const Votes = Lazy(import('pages/User/Votes/VotesModule'), <TabLoader />);
const Settings = Lazy(
  import('pages/User/Settings/SettingsModule'),
  <TabLoader />
);

export const App = (): JSX.Element => {
  // const connectedWallets = useWallets();
  // const [{ wallet }] = useConnectWallet();
  // const refWallets = useRef<WalletState[]>();

  const { xsublimatioStore, stencilsStore } = useRootStore();

  const { mutate: initProjectStores } = useMutation(
    () => Promise.all([xsublimatioStore.init(), stencilsStore.init()]),
    {
      onError() {
        toast.error('Error loading data', {
          position: 'top-right',
        });
      },
    }
  );

  useEffect(() => {
    initProjectStores();
  }, []);

  // refWallets.current = connectedWallets;

  return (
    <>
      <Theme>
        <AnimatePresence>
          <Routes location={location} key={location.pathname}>
            <Route path={PATHS.HOME} element={<Layout />}>
              <Route index element={<Home />} />

              <Route path={PATHS.FEATURED.INDEX} element={<Featured />}>
                <Route path={PATHS.FEATURED.LIVE} element={<FeaturedLive />} />
                <Route
                  path={PATHS.FEATURED.UPCOMING}
                  element={<FeaturedUpcoming />}
                />
                <Route path={PATHS.FEATURED.ALL} element={<FeaturedAll />} />
              </Route>

              <Route path={PATHS.STENCILS_PROJECT.INDEX} element={<Stencils />}>
                <Route
                  path={PATHS.STENCILS_PROJECT.NFT_CATALOG}
                  element={<StencilsNftCatalog />}
                />
                <Route
                  path={PATHS.STENCILS_PROJECT.MY_BAG}
                  element={<StencilsMyBag />}
                />
              </Route>

              <Route path={PATHS.PROJECT.INDEX} element={<Project />}>
                <Route
                  path={PATHS.PROJECT.CREATE_DRUG.INDEX}
                  element={<CreateDrug />}
                >
                  <Route
                    path={PATHS.PROJECT.CREATE_DRUG.ALL}
                    element={<CreateDrugContent />}
                  />
                  <Route
                    path={PATHS.PROJECT.CREATE_DRUG.AVAILABLE}
                    element={<CreateDrugContent />}
                  />
                </Route>
                <Route
                  path={PATHS.PROJECT.NFT_CATALOG.INDEX}
                  element={<NftCatalog />}
                >
                  <Route
                    path={PATHS.PROJECT.NFT_CATALOG.TEXT}
                    element={<NftCatalogText />}
                  />
                  <Route
                    path={PATHS.PROJECT.NFT_CATALOG.VISUAL}
                    element={<NftCatalogContent />}
                  />
                </Route>

                <Route path={PATHS.PROJECT.MY_BAG.INDEX} element={<MyBag />}>
                  <Route
                    path={PATHS.PROJECT.MY_BAG.TEXT}
                    element={<TextView />}
                  />

                  <Route
                    path={PATHS.PROJECT.MY_BAG.VISUAL.INDEX}
                    element={<VisualView />}
                  >
                    <Route
                      path={PATHS.PROJECT.MY_BAG.VISUAL.DRUGS}
                      element={<VisualGridCards />}
                    />
                    <Route
                      path={PATHS.PROJECT.MY_BAG.VISUAL.MOLECULES}
                      element={<VisualGridCards />}
                    />
                  </Route>
                </Route>

                {/*<Route path={PATHS.PROJECT.RECIPES.INDEX} element={<Recipes />}>*/}
                {/*  <Route*/}
                {/*    path={PATHS.PROJECT.RECIPES.DRUGS}*/}
                {/*    element={<DrugView />}*/}
                {/*  />*/}
                {/*  <Route*/}
                {/*    path={PATHS.PROJECT.RECIPES.MOLECULES}*/}
                {/*    element={<MoleculeView />}*/}
                {/*  />*/}
                {/*</Route>*/}
              </Route>

              <Route path={PATHS.ARTWORK.INDEX}>
                <Route index element={<ArtWork />} />
                <Route
                  path={PATHS.ARTWORK.VIDEO}
                  element={<ArtworkVideoPage />}
                />
              </Route>

              <Route path={PATHS.DAO.INDEX} element={<Dao />}>
                <Route path={PATHS.DAO.MEMBERS} element={<Members />} />
                <Route path={PATHS.DAO.MANIFESTO} element={<Manifesto />} />
                <Route path={PATHS.DAO.DIAGRAM} element={<Diagram />} />
                {/*<Route path={PATHS.DAO.METRICS} element={<Metrics />} />*/}
                {/*<Route path={PATHS.DAO.PROPOSALS} element={<Proposals />} />*/}
              </Route>

              <Route path={PATHS.USER.INDEX} element={<User />}>
                <Route path={PATHS.USER.COLLECTED} element={<Collected />} />
                {/*<Route path={PATHS.USER.FAVORITES} element={<Favorites />} />*/}
                <Route path={PATHS.USER.PROJECTS} element={<Projects />} />
                {/*<Route path={PATHS.USER.VOTES} element={<Votes />} />*/}
                <Route path={PATHS.USER.SETTINGS} element={<Settings />} />
              </Route>

              <Route path={PATHS.TERMS} element={<Terms />} />
              <Route path={PATHS.PRIVACY} element={<PrivacyPolicy />} />
              <Route path={PATHS.ABOUT} element={<About />} />
            </Route>

            <Route path={PATHS.NOT_FOUND} element={<NotFound />} />
          </Routes>
        </AnimatePresence>
      </Theme>
    </>
  );
};
