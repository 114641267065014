import styled from 'styled-components/macro';

import { BorderlessButton } from 'modules/components/Button';
import SmartImage from 'modules/components/SmartImage';

export const Card = styled.div`
  position: relative;
`;
export const TitleSection = styled.div`
  display: flex;
  height: 85px;
  border-bottom: solid 0.5px ${(props) => props.theme.colors.primary[5]};
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //padding-bottom: 50px;
  flex: 1;
`;
export const BackButton = styled(BorderlessButton)`
  width: 85px;
`;
export const ActionBar = styled.div`
  padding: 0px 50px 50px;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
`;
export const GhostButton = styled.div`
  width: 85px;
`;

export const InfoSection = styled.div`
  padding: 20px 0px 0px 50px;
`;
export const Title = styled.div`
  font-size: 25px;
  line-height: 32px;
  font-weight: 500;
`;
export const Description = styled.div`
  font-size: 18px;
  line-height: 23px;
`;
export const Items = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  height: 100%;
  width: 100%;
  gap: 30px;
  padding-top: 20px;
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &:first-of-type {
    padding-left: 50px;
  }

  &:last-of-type {
    padding-right: 50px;
  }
`;
export const SpinnerContainer = styled.div`
  height: 270px;
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ItemImage = styled(SmartImage)`
  //height: 270px;
  //width: 270px;
  box-shadow: 0px 0px 0px 15px rgba(19, 71, 255, 0);
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 0px 0px 15px rgba(19, 71, 255, 0.5);
  }

  &.active {
    box-shadow: 0px 0px 0px 15px rgba(19, 71, 255, 1);
  }
`;
export const ItemId = styled.div`
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
`;
