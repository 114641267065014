// https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
// https://dev.to/swastikyadav/convert-px-to-rem-an-effective-workflow-4m4j

export const clampBuilder = (
  minWidthPx: number,
  maxWidthPx: number,
  minFontSize: number,
  maxFontSize: number
): string => {
  const root = document.querySelector('html');
  let pixelsPerRem = 0;
  if (root) {
    pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2)) || 16;
  }

  const minWidth = minWidthPx / pixelsPerRem;
  const maxWidth = maxWidthPx / pixelsPerRem;

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minFontSize;

  return `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxFontSize}rem)`;
};

export const calculateCh = (element: HTMLElement, fontSize: string): number => {
  const zero = document.createElement('span');
  zero.innerText = '0';
  zero.style.position = 'absolute';
  zero.style.fontSize = fontSize;

  element.appendChild(zero);
  const chPixels = zero.getBoundingClientRect().width;
  element.removeChild(zero);

  return chPixels;
};

export const pixelsToRem = (px: number): number => {
  const root = document.querySelector('html');
  let baseFontSize = 0;
  if (root) {
    baseFontSize = Number(getComputedStyle(root).fontSize.slice(0, -2)) || 16;
  }

  return (1 / baseFontSize) * px;
};

export const remToPixels = (rem: number): number => {
  const root = document.querySelector('html');
  let baseFontSize = 0;
  if (root) {
    baseFontSize = Number(getComputedStyle(root).fontSize.slice(0, -2)) || 16;
  }
  return rem / (1 / baseFontSize);
};
