import styled from 'styled-components/macro';

export const CardWrapperStyled = styled.div`
  //max-width: 900px;
  //height: 720px;
  width: 100%;
  height: 100%;
`;

export const CloseBtn = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary[4]};
  z-index: 10;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.primary[3]};
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;
