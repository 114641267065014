import { createGlobalStyle } from 'styled-components/macro';

import { Fonts } from 'theme/Fonts';
import { Reset } from 'theme/Reset';

export const GlobalStyle = createGlobalStyle`
  ${Fonts}
	${Reset}

  html,
  body
  {
    padding: 0;
    margin: 0;
    height: 100%;
  }
  
  html {
    overscroll-behavior: none;
    scroll-behavior: smooth;
	  font-size: 16px;
    line-height: 1;
	  color: rgba(51, 51, 51, 1);
  }
  
  body {

    box-sizing: border-box;
    font-family: 'Koopman', sans-serif;
	  
  }
  

  #root {
    display: flex;
    flex: 1 1 auto;
    height: 100%;
  }

  @supports (font-variation-settings: normal) {
	  // TODO @max please make sure if its correct work with another font-weight, maybe need update styles for buttons and typography
    //body {
    //  font-family: 'Koopman VF', sans-serif;
    //}
  }

  a {
	  text-decoration: none;
    color: rgba(51, 51, 51, 1);
  }
`;
