import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  ReactNode,
  ForwardRefRenderFunction,
} from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components/macro';
import { AnimatePresence, motion } from 'framer-motion';

interface ModalProps {
  defaultOpened?: boolean;
  children: ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  backDropClassName?: string;
  isBodyScroll?: boolean;
}

export interface ModalHandlers {
  open: () => void;
  close: () => void;
}

const ModalStyled = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
`;

const BackdropStyled = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  ${({ className }) =>
    className &&
    className === 'whiteBackDrop' &&
    `
    background-color: rgba(255, 255, 255, 0.5);
  `}
`;

const BodyStyled = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 900px;
  max-height: 720px;
  background-color: ${(props) => props.theme.colors.primary[9]};
  border-radius: 10px;
  border: 0.5px solid ${(props) => props.theme.colors.primary[5]};
`;

const modalElement = document.getElementById('app-modal');

const Modal: ForwardRefRenderFunction<ModalHandlers, ModalProps> = (
  props,
  ref
) => {
  const {
    defaultOpened = false,
    children,
    onOpen,
    onClose,
    className,
    backDropClassName,
    isBodyScroll,
  } = props;

  const [isOpen, setIsOpen] = useState(defaultOpened);

  useEffect(() => {
    setIsOpen(defaultOpened);
  }, [defaultOpened]);

  const close = () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  const open = () => {
    if (onOpen) {
      onOpen();
    }

    setIsOpen(true);
  };

  useImperativeHandle(
    ref,
    () => ({
      open,
      close,
    }),
    [close, open]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        close();
      }
    },
    [close]
  );

  // escape handle close
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleEscape, false);
    }
    return () => {
      document.removeEventListener('keydown', handleEscape, false);
    };
  }, [handleEscape, isOpen]);

  // disable scroll
  useEffect(() => {
    if (isOpen) {
      if (isBodyScroll) {
        document.body.setAttribute('style', 'overflow: hidden');
      }
      document.body.setAttribute('style', 'overflow: hidden');
      document.body.removeAttribute('style');
    } else {
      document.body.removeAttribute('style');
    }

    return () => {
      document.body.removeAttribute('style');
    };
  });

  return (
    modalElement &&
    createPortal(
      isOpen ? (
        <AnimatePresence>
          <ModalStyled>
            <BackdropStyled onClick={close} className={backDropClassName} />
            <BodyStyled
              className={className}
              transition={{
                duration: 0.3,
              }}
              initial={{ opacity: 0, y: '10%', x: '-50%' }}
              animate={{
                opacity: 1,
                x: '-50%',
                y: '-50%',
              }}
              exit={{ opacity: 0, y: '100%', x: '-50%' }}
            >
              {children}
            </BodyStyled>
          </ModalStyled>
        </AnimatePresence>
      ) : null,
      modalElement
    )
  );
};

export default forwardRef(Modal);
