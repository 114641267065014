import { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { StoredDrug } from 'stores/XSublimatio/XSublimatioStore';
import { Icon } from 'modules/components/Icon';

type CompositionItems = StoredDrug['composition'];

interface ICompositionOverlay {
  composition: CompositionItems;
}

const Wrapper = styled(motion.div)`
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  overflow: hidden;
  bottom: 70px;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.primary[9]};
  border: 0.5px solid rgba(0, 0, 0, 0.5);
  font-size: 17px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
  padding: 20px;
  overflow-y: auto;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  height: fit-content;
  align-items: center;
`;

const NameStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CheckboxStyled = styled.div`
  color: ${(props) => props.theme.colors.primary[5]};
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Checkbox: FC<{ isChecked?: boolean }> = ({ isChecked = false }) => {
  return (
    <CheckboxStyled>
      {isChecked ? (
        <Icon name={'checkbox-filled'} />
      ) : (
        <Icon name={'checkbox-empty'} />
      )}
    </CheckboxStyled>
  );
};

export const CompositionOverlay: FC<ICompositionOverlay> = ({
  composition,
}) => {
  const renderCompositionItems = () => (
    <>
      {composition.map((compositionItem, index) => (
        <Item key={index}>
          <Checkbox isChecked={compositionItem.isAvailable} />
          <NameStyled>{compositionItem.name}</NameStyled>
        </Item>
      ))}
    </>
  );

  return (
    <AnimatePresence>
      <Wrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {renderCompositionItems()}
      </Wrapper>
    </AnimatePresence>
  );
};
