import * as React from 'react';

import styled from 'styled-components/macro';

import useDynamicSVGImport from 'hooks/useDynamicImportSvg';

export type TIconName =
  | 'logo'
  | 'logo-short'
  | 'heart'
  | 'molecule'
  | 'arrow-filter'
  | 'pen'
  | 'copy'
  | 'cross'
  | 'check'
  | 'info-circle'
  | 'wallet'
  | 'gear'
  | 'user'
  | 'user-filled'
  | 'user-filled-circle'
  | 'logout'
  | 'dark-mode'
  | 'clipboard-alt'
  | 'twitter'
  | 'medium'
  | 'instagram'
  | 'logo-cross'
  | 'discord'
  | 'telegram'
  | 'long-left-arrow'
  | 'long-right-arrow'
  | 'arrow-bold-right'
  | 'arrow-bold-left'
  | 'invoice'
  | 'recipes'
  | 'wallet-solid'
  | 'bolt'
  | 'dialpad'
  | 'shopping-bag'
  | 'live'
  | 'circle-live'
  | 'circle-question'
  | 'circle-full-screen'
  | 'ethereum'
  | 'viewers'
  | 'share'
  | 'grid-horizontal'
  | 'chevron-down'
  | 'info-circle-filled'
  | 'refresh'
  | 'search'
  | 'metamask'
  | 'walletconnect'
  | 'walletlink'
  | 'xdefi'
  | 'burger'
  | 'bank'
  | 'book'
  | 'checked-box'
  | 'document'
  | 'envelope'
  | 'eye'
  | 'fire'
  | 'home'
  | 'moon'
  | 'palette'
  | 'power-button'
  | 'settings'
  | 'star'
  | 'sun'
  | 'arrow-down'
  | 'caret-right'
  | 'arrow-bold-down'
  | 'arrow-bold-up'
  | 'checkbox-empty'
  | 'checkbox-filled'
  | 'arrow-play'
  | 'circle-play'
  | 'circle-pause'
  | 'pause'
  | 'volume-down'
  | 'volume-up'
  | 'volume-mute'
  | 'volume-off'
  | 'fullscreen'
  | 'closecross';

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: TIconName;
  onCompleted?: () => void;
  onError?: () => void;
}

const Placeholder = styled.div`
  min-width: 24px;
  min-height: 24px;
`;

/**
 * Simple wrapper for dynamic SVG import hook. You can implement your own wrapper,
 * or even use the hook directly in your components.
 */
export const Icon = (props: IconProps): JSX.Element | null => {
  const { name, onCompleted, onError, ...rest } = props;
  const { error, loading, SvgIcon } = useDynamicSVGImport(name, {
    onCompleted,
    onError,
  });

  if (error) {
    return <>{error.message}</>;
  }
  if (loading) {
    return <Placeholder></Placeholder>;
  }
  if (SvgIcon) {
    return <SvgIcon {...rest} />;
  }
  return null;
};
