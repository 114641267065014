import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const GradientButtonStyled = styled(Button)`
  position: relative;
  border: none;
  ${({ theme }) => css`
    background: ${theme.colors.gradient[1]};
    color: ${theme.colors.primary[9]};

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${theme.colors.gradient[2]};
      opacity: 0;
      visibility: hidden;
      z-index: -1;
      transition: all 0.25s ease;
    }

    &:active {
      background: ${theme.colors.gradient[2]};
    }

    &:disabled {
      background: ${theme.colors.gradient[3]};
    }

    &:hover {
      &:before {
        opacity: 1;
        visibility: visible;
        z-index: 1;
      }
    }
  `}
`;

const PrimaryButton: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return <GradientButtonStyled {...rest}>{children}</GradientButtonStyled>;
};

export default PrimaryButton;
