import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import { Icon, TIconName } from 'modules/components/Icon';

import BaseButton, { BaseButtonProps } from './BaseButton';

type TSize = 'xs' | 's' | 'm' | 'l' | 'xl';

export interface ICircleIcon {
  name: TIconName;
  size?: number;
}

export interface CircleButtonProps extends BaseButtonProps {
  size?: TSize;
  icon?: ICircleIcon;
}

const CircleButtonStyled = styled(BaseButton)<{ size: TSize }>`
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  //cursor: pointer;
  ${({ theme, size }) => css`
    width: ${theme.buttons.circle.size[size].size};
    height: ${theme.buttons.circle.size[size].size};
    font-size: ${theme.buttons.circle.size[size].font.fontSize};
    font-weight: ${theme.buttons.circle.size[size].font.fontWeight};
    line-height: ${theme.buttons.circle.size[size].font.lineHeight};
  `}
`;

const IconWrapperStyled = styled.span`
  color: currentColor;
  line-height: 0;
  font-size: 0;
  z-index: 2;
`;

const CircleButton: FC<CircleButtonProps> = (props) => {
  const { children, size = 'm', icon, ...rest } = props;
  return (
    <CircleButtonStyled {...rest} size={size}>
      {icon ? (
        <IconWrapperStyled>
          <Icon
            name={icon.name}
            style={{
              width: `${icon.size || 24}px`,
              height: `${icon.size || 24}px`,
            }}
          />
        </IconWrapperStyled>
      ) : (
        children
      )}
    </CircleButtonStyled>
  );
};

export default CircleButton;
