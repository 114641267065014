import React from 'react';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';

import { App } from 'modules/app/App';
import { GlobalStyle } from 'theme/GlobalStyle';
import { RootStoreProvider } from 'providers/RootStoreProvider';

import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      // retry: false,
    },
  },
});

// if (process.env.NODE_ENV === 'production') {
//   console.log(`Production env: ${dayjs().format('YYYY-MM-DD HH:mm:ss')}`);
// }

ReactDOM.render(
  <>
    <GlobalStyle />
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <RootStoreProvider>
            <App />
          </RootStoreProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </React.Fragment>
    <ToastContainer />
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
