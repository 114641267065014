import { LoadingComponentProps } from 'react-loadable';

interface RouterLoadingProps extends LoadingComponentProps {
  loaderComponent: JSX.Element;
}

const RouterLoading = (props: RouterLoadingProps): JSX.Element | null => {
  const { error, pastDelay, retry, timedOut, loaderComponent } = props;

  if (error) {
    return (
      <div>
        {error.message} <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (timedOut) {
    return (
      <div>
        Taking a long time... <button onClick={retry}>Retry</button>
      </div>
    );
  } else if (pastDelay) {
    return loaderComponent;
  } else {
    return null;
  }
};

export default RouterLoading;
