import * as React from 'react';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Composition, Only } from 'atomic-layout';
import { observer } from 'mobx-react-lite';

import { Icon } from 'modules/components/Icon';
import { LINKS } from 'router/config';
import { AdditionalRoutes } from 'modules/app/Header/AdditionalRoutes';
import { UserMenu } from 'modules/components/UserMenu/UserMenu';
import UserMenuMobile from 'modules/components/UserMenu/UserMenuMobile';
import Status from 'modules/components/Connect/Status';
import { useRootStore } from 'providers/RootStoreProvider';

import { SocialList } from './SocialList';

const HeaderNavigationStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary[9]};
  box-shadow: 0px 4px 20px rgba(19, 71, 255, 0.1);
  height: 82px;
  z-index: 100;
`;

const ControlsWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const LogoDesktopStyled = styled.div`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[3]};
  svg {
    width: 374px;
    height: 26px;
  }
`;

const LogoMobileStyled = styled.div`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary[5]};
  svg {
    width: 196px;
    height: 12px;
  }
`;

const NavWrapperStyled = styled.div`
  position: relative;
  margin-right: 50px;
  &:last-child {
    margin-right: 0;
  }
`;

const NavItemWrapper = styled.div`
  position: relative;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
`;

const UserItemWrapper = styled.div`
  position: relative;
  margin-right: 20px;
`;

const UserControlsWrapperStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const areas = `
  logo controls
`;

const Header = observer((): JSX.Element => {
  const { web3Store } = useRootStore();

  const isActive = web3Store.isActive;

  const handleOpenWalletModal = () => {
    web3Store.connect();
  };

  return (
    <Composition
      as={HeaderNavigationStyled}
      areas={areas}
      templateColsMd={'repeat(2, 1fr)'}
      templateCols={'auto 48px'}
      alignItems={'flex-end'}
      paddingHorizontal={'20px'}
      paddingBottom={'20px'}
      paddingHorizontalLg={'100px'}
      paddingBottomMd={'0'}
      alignItemsMd={'center'}
      justifyContent={'space-between'}
    >
      {(Areas) => {
        return (
          <>
            <Areas.Logo justify={'center'} justifyMd={'start'}>
              <NavLink to={LINKS.HOME}>
                <Only from={'md'}>
                  <LogoDesktopStyled>
                    <Icon name={'logo'} />
                  </LogoDesktopStyled>
                </Only>

                <Only to={'md'}>
                  <LogoMobileStyled>
                    <Icon name={'logo'} />
                  </LogoMobileStyled>
                </Only>
              </NavLink>
            </Areas.Logo>
            <Areas.Controls justify={'end'}>
              <Only from={'xl'}>
                <ControlsWrapperStyled>
                  <NavWrapperStyled>
                    <AdditionalRoutes />
                  </NavWrapperStyled>
                  <NavWrapperStyled>
                    <SocialList />
                  </NavWrapperStyled>
                  <UserControlsWrapperStyled>
                    {isActive && (
                      <UserItemWrapper>
                        <UserMenu />
                      </UserItemWrapper>
                    )}
                    <NavItemWrapper>
                      <Status onClick={handleOpenWalletModal} />
                    </NavItemWrapper>
                  </UserControlsWrapperStyled>
                </ControlsWrapperStyled>
              </Only>
              <Only to={'xl'}>
                <UserMenuMobile />
              </Only>
            </Areas.Controls>
          </>
        );
      }}
    </Composition>
  );
});

export default Header;
