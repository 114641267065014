import { Outlet } from 'react-router-dom';
import styled from 'styled-components/macro';

import Header from 'modules/app/Header/Header';
import Footer from 'modules/app/Footer/Footer';

const AppStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding-top: 82px;
`;

const Layout = (): JSX.Element => {
  return (
    <AppStyled>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </AppStyled>
  );
};

export default Layout;
