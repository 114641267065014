import * as React from 'react';

import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { LINKS } from 'router/config';

const AdditionalRoutesStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const NavItemWrapper = styled.div`
  position: relative;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
`;

const TextNavigationItemStyled = styled(NavLink)`
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary[29]};
  text-decoration: none;
  transition: color 0.25s ease;
  &.active {
    color: ${({ theme }) => theme.colors.primary[5]};
    pointer-events: none;
    cursor: none;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.primary[5]};
  }
`;

export const AdditionalRoutes = (): JSX.Element => {
  return (
    <AdditionalRoutesStyled>
      <NavItemWrapper>
        <TextNavigationItemStyled to={LINKS.ABOUT}>
          About
        </TextNavigationItemStyled>
      </NavItemWrapper>
      <NavItemWrapper>
        <TextNavigationItemStyled to={LINKS.FEATURED.INDEX}>
          Artists
        </TextNavigationItemStyled>
      </NavItemWrapper>
      <NavItemWrapper>
        <TextNavigationItemStyled to={LINKS.DAO.MEMBERS}>
          DAO
        </TextNavigationItemStyled>
      </NavItemWrapper>
    </AdditionalRoutesStyled>
  );
};
