import { FC } from 'react';

import { ThemeProvider } from 'styled-components/macro';

const lightTheme = {
  colors: {
    primary: {
      1: 'rgba(19, 71, 255, 1)', // faction blue
      2: 'rgba(28, 60, 173, 1)', // dark blue
      3: 'rgba(51, 51, 51, 1)', // dark gray
      4: 'rgba(128, 128, 128, 1)',
      5: 'rgba(0, 0, 0, 1)', // black
      6: 'rgba(243, 246, 255, 1)', // blue gray light
      7: 'rgba(248, 248, 252, 1)',
      8: 'rgba(248, 251, 255, 1)',
      9: 'rgba(255, 255, 255, 1)', // white
      10: 'rgba(166, 166, 166, 1)',
      11: 'rgba(217, 217, 217, 1)', // medium gray
      12: 'rgba(229, 229, 229, 1)',
      13: 'rgba(242, 242, 242, 1)', // light gray
      14: 'rgba(249, 249, 249, 1)',
      15: 'rgba(0, 0, 0, 0.5)', // pale black
      16: 'rgba(204, 204, 204, 1)', // pale gray
      17: 'rgba(76, 76, 76, 1)',
      18: 'rgba(153, 153, 153, 1)',
      19: 'rgba(231, 237, 255, 1)',
      20: 'rgba(3, 38, 164, 1)',
      21: 'rgba(208, 218, 255, 1)',
      22: 'rgba(26, 26, 26, 1)',
      23: 'rgba(250, 250, 250, 1)',
      24: 'rgba(108, 108, 108, 1)',
      25: 'rgba(246, 249, 248, 1)',
      26: 'rgba(196, 196, 196, 1)',
      27: 'rgba(255, 255, 255, 0.5)', // pale white
      28: 'rgba(250, 251, 255, 1)', // pale blue
      29: 'rgba(0, 0, 0, 0.5)', // pale black
      30: 'rgba(242, 244, 251, 1)',
    },
    secondary: {
      1: 'rgba(137, 163, 255, 1)',
      2: 'rgba(192, 199, 226, 1)',
      3: 'rgba(228, 6, 174, 1)',
      4: 'rgba(123, 97, 255, 1)',
      5: 'rgba(80, 255, 19, 1)',
      6: 'rgba(255, 19, 175, 1)',
      7: 'rgba(19, 87, 43, 1)',
    },
    gradient: {
      1: 'linear-gradient(90deg, #1347FF 0%, #CA13DA 100%)',
      2: 'linear-gradient(90deg, #09268C 0%, #79095D 100%)',
      3: 'linear-gradient(90deg, #DCE4FF 0%, #FAC1FF 100%)',
      4: 'linear-gradient(285.36deg, #000000 14.83%, #171717 93.55%)',
    },
  },

  buttons: {
    main: {
      size: {
        xs: {
          height: '40px',
          width: '120px',
          indent: '10px 20px',
          font: {
            fontSize: '15px',
            lineHeight: '19px',
            fontWeight: 500,
          },
        },
        s: {
          height: '40px',
          width: '180px',
          indent: '10px 20px',
          font: {
            fontSize: '15px',
            lineHeight: '19px',
            fontWeight: 500,
          },
        },
        m: {
          height: '65px',
          width: '200px',
          indent: '10px 20px',
          font: {
            fontSize: '15px',
            lineHeight: '19px',
            fontWeight: 500,
          },
        },
        l: {
          height: '65px',
          width: '300px',
          indent: '10px 20px',
          font: {
            fontSize: '18px',
            lineHeight: '23px',
            fontWeight: 500,
          },
        },
        xl: {
          height: '85px',
          width: '430px',
          indent: '10px 20px',
          font: {
            fontSize: '20px',
            lineHeight: '25px',
            fontWeight: 500,
          },
        },
      },
      shadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    },

    circle: {
      size: {
        xs: {
          size: '20px',
          font: {
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 400,
          },
        },
        s: {
          size: '50px',
          font: {
            fontSize: '18px',
            lineHeight: '23px',
            fontWeight: 500,
          },
        },
        m: {
          size: '70px',
          font: {
            fontSize: '13px',
            lineHeight: '17px',
            fontWeight: 400,
          },
        },
        l: {
          size: '80px',
          font: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: 400,
          },
        },
        xl: {
          size: '100px',
          font: {
            fontSize: '15px',
            lineHeight: '19px',
            fontWeight: 400,
          },
        },
      },
    },
  },

  radii: {
    round: '10px',
    pill: '40px',
    circle: '50%',
  },

  breakpoints: {
    xs: '0px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },
};

export const themeConfig = {
  ...lightTheme,
};

export const Theme: FC = ({ children }) => (
  <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
);
