import styled from 'styled-components/macro';

import {
  SharedTypography,
  getSize,
} from 'modules/components/Typography/shared';

const H1 = styled.div`
  ${SharedTypography};
  font-size: ${getSize(50, 150)};
  line-height: ${getSize(70, 191)};
`;
const H2 = styled.div`
  ${SharedTypography};
  font-size: ${getSize(40, 100)};
  line-height: ${getSize(50, 90)};
`;
const H3 = styled.div`
  ${SharedTypography};
  font-size: ${getSize(25, 60)};
  line-height: ${getSize(37, 76)};
`;
const H4 = styled.div`
  ${SharedTypography};
  font-size: ${getSize(22, 50)};
  line-height: ${getSize(33, 64)};
`;

const H5 = styled.div`
  ${SharedTypography};
  font-size: ${getSize(20, 45)};
  line-height: ${getSize(28, 57)};
`;

const H6 = styled.div`
  ${SharedTypography};
  font-size: ${getSize(20, 30)};
  line-height: ${getSize(31, 38)};
`;

const SubTitle = styled.div`
  ${SharedTypography};
  font-size: ${getSize(20, 25)};
  line-height: ${getSize(28, 32)};
`;

const BodyLarge = styled.p`
  ${SharedTypography};
  font-size: ${getSize(18, 20)};
  line-height: ${getSize(20, 25)};
`;

const BodyMedium = styled.p`
  ${SharedTypography};
  font-size: ${getSize(16, 18)};
  line-height: ${getSize(19, 23)};
`;

const BodyRegular = styled.p`
  ${SharedTypography};
  font-size: ${getSize(16, 17)};
  line-height: ${getSize(18, 22)};
`;

const BodySmall = styled.span`
  ${SharedTypography};
  font-size: ${getSize(14, 15)};
  line-height: ${getSize(16, 19)};
`;

export {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  SubTitle,
  BodyLarge,
  BodyMedium,
  BodyRegular,
  BodySmall,
};
