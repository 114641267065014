import { useEffect, useState } from 'react';

import GridList from 'react-gridlist';
import { useMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { StoredDrug } from 'stores/XSublimatio/XSublimatioStore';
import { CreateNftCard } from 'modules/components/CreateNftCard';
import { useRootStore } from 'providers/RootStoreProvider';
import { GridWrapperStyled } from 'pages/Project/components/Laboratory/tabs/MyBag/styles';
import ErrorTemplate from 'modules/components/ErrorTemplate';
import { LINKS } from 'router/config';
import { CreateDrugModal } from 'modules/components/CreateDrugModal/CreateDrugModal';
import { TabLoader } from 'modules/app/App';

const CARD_WIDTH = 330;
const CARD_HEIGHT = 520;
const GRID_GAP = 20;

function getGridGap(elementWidth: number, windowHeight: number) {
  return GRID_GAP;
}

function getColumnCount(elementWidth: number, gridGap: number) {
  // return Math.floor((elementWidth + gridGap) / (CARD_WIDTH + gridGap));
  if (window.innerWidth < 480) {
    return 1;
  }

  if (window.innerWidth < 992) {
    return 2;
  }

  if (window.innerWidth < 1440) {
    return 3;
  }

  if (window.innerWidth < 2050) {
    return 4;
  }

  return 5;
}

function getWindowMargin(windowHeight: number) {
  return Math.round(windowHeight * 1.5);
}

function getItemData(drug: StoredDrug, columnWidth: number) {
  return {
    key: drug.name,
    height: CARD_HEIGHT,
  };
}

const CreateDrugContent = observer((): JSX.Element => {
  const { xsublimatioStore, xsublimatioController } = useRootStore();
  const [isPopupExposed, setPopupExposed] = useState(false);
  const match = useMatch('/project/:projectType/create-drug/:path');

  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, 500);
  }, []);

  const exposePopup = () => setPopupExposed(true);
  const closePopup = () => {
    xsublimatioController.resetCreateDrug();
    setPopupExposed(false);
  };

  const filters = {
    all: () => true,
    available: (drug: StoredDrug) => drug.brewPossibility.canBrew,
  };

  const filter = match?.params.path === 'all' ? filters.all : filters.available;

  if (xsublimatioStore.drugs.filter(filter).length === 0) {
    return (
      <ErrorTemplate
        type={'empty_data'}
        text={'No molecules'}
        link={{
          url: LINKS.PROJECT('xsublimatio').CREATE_DRUG.ALL,
          text: 'Buy molecules',
        }}
      />
    );
  }

  const filteredDrugs = xsublimatioStore.drugs.filter(filter);

  return (
    <>
      {isShowing ? (
        <GridWrapperStyled>
          <GridList
            items={filteredDrugs}
            getGridGap={getGridGap}
            getColumnCount={getColumnCount}
            getWindowMargin={getWindowMargin}
            getItemData={getItemData}
            fixedColumnWidth={CARD_WIDTH}
            renderItem={(drug) => {
              return (
                <CreateNftCard
                  drug={drug}
                  key={`drug-card-${drug.drugId}`}
                  onOpenModal={exposePopup}
                />
              );
            }}
          />
        </GridWrapperStyled>
      ) : (
        <TabLoader />
      )}
      {isPopupExposed && xsublimatioController.createDrug && (
        <CreateDrugModal
          drug={xsublimatioController.createDrug}
          onClose={closePopup}
        />
      )}
    </>
  );
});

export default CreateDrugContent;
