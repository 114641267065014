import * as React from 'react';

interface UseDynamicSVGImportOptions {
  onCompleted?: (
    name: string,
    SvgIcon: React.FC<React.SVGProps<SVGSVGElement>> | undefined
  ) => void;
  onError?: (err: Error) => void;
}

const useDynamicSVGImport = (
  name: string,
  options: UseDynamicSVGImportOptions = {}
) => {
  const ImportedIconRef =
    React.useRef<React.FC<React.SVGProps<SVGSVGElement>>>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();

  const { onCompleted, onError } = options;

  const importIcon = async (): Promise<void> => {
    try {
      ImportedIconRef.current = (
        await import(
          `!!@svgr/webpack?-svgo,+titleProp,+ref!assets/icons/${name}.svg`
        )
      ).default;
      onCompleted?.(name, ImportedIconRef.current);
    } catch (err: any) {
      onError?.(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (name) {
      setLoading(true);
      importIcon();
    }

    return () => {
      setLoading(false);
    };
  }, [name]);

  return { error, loading, SvgIcon: ImportedIconRef.current };
};

export default useDynamicSVGImport;
