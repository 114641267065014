import { css } from 'styled-components/macro';

import { clampBuilder, pixelsToRem } from 'utils/typography';

interface ITypographyProps {
  fontStyle?: string;
  fontWeight?: number;
  textTransform?: string;
  truncate?: boolean;
}

export const getSize = (minFonSize: number, maxFontSize: number): string => {
  return clampBuilder(
    320,
    960,
    pixelsToRem(minFonSize),
    pixelsToRem(maxFontSize)
  );
};

export const SharedTypography = css<ITypographyProps>`
  margin: 0;
  color: currentColor;
  font-style: ${({ fontStyle = 'normal' }) => fontStyle};
  font-weight: ${({ fontWeight = 400 }) => fontWeight};
  text-transform: ${({ textTransform = 'none' }) => textTransform};
  ${({ truncate }) =>
    truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
