import { makeAutoObservable } from 'mobx';

import { Project, ProjectTypeType } from 'api/generated/graphql';
import { LINKS } from 'router/config';

import { FilterTypes, LiveProject, UpcomingProject } from './models';
import { getStatus, hasEnded, isLive } from './interpreters';

export default class FeaturedProjectsStore {
  public liveData: LiveProject[] = [];

  public upcomingData: UpcomingProject[] = [];

  public allData: (UpcomingProject | LiveProject)[] = [];

  constructor() {
    this.liveData = [];
    this.upcomingData = [];
    this.allData = [];
    makeAutoObservable(this);
  }

  public mapToData = (project: Project): LiveProject | UpcomingProject => {
    const { name, artistName, mintType } = project;

    const baseData = {
      name: name || '',
      artistName: artistName || '',
      mintType: mintType || '',
      status: getStatus(project),
      hasEnded: hasEnded(project),
      isLive: isLive(project),
      mintCount: project.artworksCount || 0,
      mintLimit: project.mintLimit || 0,
      artistAddress: project.artist?.address || undefined,
    };

    if (isLive(project)) {
      return {
        ...baseData,
        ...this.getLiveData(project),
      } as LiveProject;
    } else {
      return {
        ...baseData,
        ...this.getUpcomingData(project),
      } as UpcomingProject;
    }
  };

  private upcomingFilter = (
    projects: (UpcomingProject | LiveProject)[]
  ): UpcomingProject[] => {
    return projects.filter(
      (project) => project.type === FilterTypes.UPCOMING
    ) as UpcomingProject[];
  };

  private liveFilter = (
    projects: (UpcomingProject | LiveProject)[]
  ): LiveProject[] => {
    return projects.filter(
      (project) => project.type === FilterTypes.LIVE
    ) as LiveProject[];
  };

  public setLive = (data: (UpcomingProject | LiveProject)[]) => {
    this.liveData = this.liveFilter(data);
  };

  public setUpcoming = (data: (UpcomingProject | LiveProject)[]) => {
    this.upcomingData = this.upcomingFilter(data);
  };

  public setAll = (data: (UpcomingProject | LiveProject)[]) => {
    this.allData = data;
  };

  private getUpcomingData = (project: Project) => ({
    type: FilterTypes.UPCOMING,
    exampleArtwork: project.homeFeaturedArtwork
      ? {
          name: '',
          image: project.homeFeaturedArtwork.publicUrl as string,
        }
      : undefined,
  });

  private getLiveData = (project: Project) => ({
    type: FilterTypes.LIVE,
    link: LINKS.PROJECT(project.type as ProjectTypeType).INDEX,
    artworks:
      project.artworks?.map((artwork) => ({
        name: artwork.name || '',
        image: artwork.thumbnailUrl || '',
        placeholder: artwork.placeholderImageUrl || '',
        link: LINKS.ARTWORK(project.type as string, artwork.tokenId as string)
          .INDEX,
      })) || [],
  });
}
