import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const PrimaryButtonStyled = styled(Button)`
  ${({ theme }) => css`
    border: none;
    background: ${theme.colors.primary[1]};
    color: ${theme.colors.primary[9]};

    &:active {
      background: ${theme.colors.primary[20]};
    }

    &:disabled {
      background: ${theme.colors.primary[19]};
    }

    &:hover {
      background: ${theme.colors.primary[2]};
    }

    // &:focus {
    //   border: 5px solid ${theme.colors.primary[20]};
    // }
  `}
`;

const PrimaryButton: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return <PrimaryButtonStyled {...rest}>{children}</PrimaryButtonStyled>;
};

export default PrimaryButton;
