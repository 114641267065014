import { FC } from 'react';

import {
  Scrollbar as ReactScrollBar,
  ScrollbarProps,
} from 'react-scrollbars-custom';
import styled from 'styled-components/macro';

const ScrollbarStyled = styled(ReactScrollBar)`
  .thumbY {
    background: ${(props) => props.theme.colors.primary[5]} !important;
  }
  .thumbX {
    background: ${(props) => props.theme.colors.primary[5]} !important;
  }
  .trackY {
    width: 5px !important;
    background: rgba(0, 0, 0, 0.1) !important;
  }
  .trackX {
    height: 5px !important;
    background: rgba(0, 0, 0, 0.1) !important;
  }
`;

export const Scrollbar = (
  props: React.PropsWithChildren<ScrollbarProps>
): JSX.Element => {
  const { children, className } = props;

  return (
    <ScrollbarStyled
      className={className}
      thumbYProps={{
        className: 'thumbY',
      }}
      thumbXProps={{
        className: 'thumbX',
      }}
      trackXProps={{
        className: 'trackX',
      }}
      trackYProps={{
        className: 'trackY',
      }}
    >
      {children}
    </ScrollbarStyled>
  );
};
