import { FC, useState } from 'react';

import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import SmartImage from 'modules/components/SmartImage';

import Button from '../Button/PrimaryButton';
import { Countdown } from './Countdown';

const Border = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.primary[4]};
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const Author = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.colors.primary[4]};
  line-height: 25px;
  font-weight: 500;
  transition: color 0.3s ease;
`;

const TitleSection = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

const ViewButton = styled.div`
  color: ${(props) => props.theme.colors.primary[4]};
  transition: color 0.3s;
`;

export const NftCardStyled = styled.div<{ noRounded?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 15px;
  border: 0.5px solid ${(props) => props.theme.colors.primary[4]};
  transition: transform 0.4s, box-shadow 0.4s;
  border-radius: ${({ noRounded }) => (noRounded ? 0 : '10px')};

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 4px 4px ${(props) => props.theme.colors.primary[26]};

    ${ViewButton} {
      color: ${(props) => props.theme.colors.primary[1]};
      font-variation-settings: 'wght' 500;
    }
    ${Author} {
      color: ${(props) => props.theme.colors.primary[1]};
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Price = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 25px;
  font-weight: 500;
`;

interface NftCardProps {
  showButton?: boolean;
  name?: string;
  imageUrl: string;
  placeholder?: string;
  artistName: string;
  href: string;
  estimateEndpoint?: string;
  tokenId: string;
  noRounded?: boolean;
}

const toTitleCase = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const NftCard: FC<NftCardProps> = ({
  showButton = true,
  name = '',
  imageUrl = '',
  artistName = '',
  href = '',
  tokenId = '',
  placeholder,
  estimateEndpoint,
  noRounded,
}) => {
  const [showEstimate, setShowEstimate] = useState(!!estimateEndpoint);
  const [imageKey, setImageKey] = useState(0);
  const [imageFailed, setImageFailed] = useState(false);

  const image = `${imageUrl}${imageKey ? `#${imageKey}` : ''}`;

  const onImageFailed = () => {
    setImageFailed(true);
  };

  const resetImage = () => {
    setImageKey(10 ** 16 * Math.random());
  };

  const onCountdownFinished = () => {
    setShowEstimate(false);

    if (imageFailed) {
      resetImage();
    }
  };

  return (
    <NavLink to={href}>
      <NftCardStyled noRounded={noRounded}>
        <ImageWrapper>
          <SmartImage
            url={image}
            placeholder={placeholder}
            width={300}
            height={300}
            key={imageKey}
            onError={onImageFailed}
          />
        </ImageWrapper>

        <TitleWrapper>
          <Author>{toTitleCase(artistName)}</Author>
          <TitleSection>
            <div>{name}</div>
            {/* <Price>
            <Icon name="ethereum" height="20" width="20" />
            0.5
          </Price> */}
          </TitleSection>
        </TitleWrapper>
        {showButton && <Button size="l">Purchase</Button>}
        <Border />
        <ActionBar>
          <ViewButton>
            {showEstimate
              ? !!estimateEndpoint && (
                  <Countdown
                    estimateEndpoint={estimateEndpoint}
                    onFinished={onCountdownFinished}
                    tokenId={tokenId}
                  />
                )
              : 'View'}
          </ViewButton>
          {/* <Icon name="heart" height="24" width="24" /> */}
        </ActionBar>
      </NftCardStyled>
    </NavLink>
  );
};
