import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import CircleButton, { CircleButtonProps } from './CircleButton';

const SecondaryCircleButtonStyled2 = styled(CircleButton)`
  ${({ theme }) => css`
    color: ${theme.colors.primary[4]};
    border-color: ${theme.colors.primary[9]};
    background: ${theme.colors.primary[9]};
    box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.25);

    &:hover {
      color: ${theme.colors.primary[4]};
      background: ${theme.colors.primary[9]};
    }
  `}
`;

const SecondaryCircleButton2: FC<CircleButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <SecondaryCircleButtonStyled2 {...rest}>
      {children}
    </SecondaryCircleButtonStyled2>
  );
};

export default SecondaryCircleButton2;
