import { FC, useState } from 'react';

import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Token } from '@faction-nfts/xsublimatio-smart-contracts';
import { Only } from 'atomic-layout';
import { observer } from 'mobx-react-lite';

import { Scrollbar } from 'modules/components/Scrollbar';
import { PrimaryButton } from 'modules/components/Button';
import Spinner from 'modules/components/Spinner/Spinner';
import SmartImage from 'modules/components/SmartImage';
import { useRootStore } from 'providers/RootStoreProvider';

import {
  ActionBar,
  BackButton,
  GhostButton,
  Card,
  Items,
  ContentContainer,
  TitleSection,
  ItemId,
  ItemImage,
  Item,
  InfoSection,
  Title,
  Description,
  SpinnerContainer,
} from './styles';

const CustomScrollbar = styled(Scrollbar)`
  height: 375px !important;

  .trackX {
    margin-left: 40px;
    width: calc(100% - 100px) !important;
  }
`;

const ItemsContainerStyled = styled(motion.div)``;

interface ISelectDrugCard {
  drug: string;
  moleculeName: string;
  moleculeCount: number;
  pageId: number;
  molecules: Token[];
  prevPage: (pageId: number) => void;
  nextPage: (selectedMolecule: Token) => Promise<void>;
}

function truncate(str: string, n: number) {
  return str.length > n ? str.substring(0, n - 1) + '...' : str;
}

export const SelectDrugCard = observer(
  ({
    drug,
    moleculeName,
    pageId,
    moleculeCount,
    molecules,
    prevPage,
    nextPage,
  }: ISelectDrugCard) => {
    const { xsublimatioStore } = useRootStore();
    const [isLoading, setLoading] = useState(false);
    const [selected, setSelected] = useState<undefined | Token>(molecules[0]);
    const hasSelection = !!selected;
    const moleculeId = pageId + 1;
    const isFirst = moleculeId === 1;
    const isLastPage = moleculeId === moleculeCount;
    const buttonText = isLastPage
      ? 'Finalize'
      : `Confirm ${moleculeId}/${moleculeCount}`;

    const previousPage = () => {
      prevPage(pageId);
    };

    const selectMolecule = async () => {
      setLoading(true);
      await nextPage(selected as Token);
      setLoading(false);
    };

    const renderItems = () =>
      molecules.map((molecule) => {
        const isSelected = selected?.id === molecule.id;
        const imageClass = isSelected ? 'active' : '';
        const shortenedId = truncate(molecule.id, 22);
        const artwork = xsublimatioStore.artworks.get(molecule.id);

        const select = () =>
          molecule.id === selected?.id
            ? setSelected(undefined)
            : setSelected(molecule);

        return (
          <Item key={`molecule-${molecule.id}`}>
            <ItemImage
              url={artwork?.thumbnail || ''}
              placeholder={
                'https://res.cloudinary.com/faction/image/upload/v1652413962/faction/xsublimatio/placeholders/PH_MOL_baxnpo.jpg'
              }
              width={270}
              height={270}
              onClick={select}
              className={imageClass}
            />
            <ItemId>#{shortenedId}</ItemId>
          </Item>
        );
      });

    return (
      <Card>
        <TitleSection>Create {drug}</TitleSection>
        <InfoSection>
          <Title>{moleculeName}</Title>
          <Description>SELECT MOLECULE</Description>
        </InfoSection>
        <ContentContainer>
          <ItemsContainerStyled
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.5,
            }}
          >
            <CustomScrollbar>
              <Items>{renderItems()}</Items>
            </CustomScrollbar>
          </ItemsContainerStyled>

          <ActionBar>
            <BackButton
              leftIcon={{ name: 'arrow-bold-left', width: 19, height: 19 }}
              disabled={isFirst}
              onClick={previousPage}
            >
              Back
            </BackButton>
            <PrimaryButton
              disabled={!hasSelection || isLoading}
              onClick={selectMolecule}
              loader={isLoading}
            >
              {buttonText}
            </PrimaryButton>
            <Only from="lg">
              <GhostButton />
            </Only>
          </ActionBar>
        </ContentContainer>
      </Card>
    );
  }
);
