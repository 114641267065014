import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

import { H5 } from 'modules/components/Typography';
import { SecondaryButton } from 'modules/components/Button';

import { ReactComponent as IconNotFound } from './assets/not_found.svg';
import { ReactComponent as Icon404 } from './assets/404.svg';
import { ReactComponent as IconNetworkError } from './assets/network_error.svg';
import { ReactComponent as IconEmptyData } from './assets/empty_data.svg';

const ErrorTemplateStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  height: 100%;
  width: 100%;
`;

interface ILink {
  url: string;
  text: string;
}

interface ErrorTemplateProps {
  type: 'not_found' | 'network_error' | 'empty_data' | '404';
  text: string;
  link?: ILink;
}

const ErrorTemplate = (props: ErrorTemplateProps): JSX.Element => {
  const { type, link, text } = props;

  const renderIcon = (): JSX.Element => {
    switch (type) {
      case 'not_found':
        return <IconNotFound />;
      case 'network_error':
        return <IconNetworkError />;
      case 'empty_data':
        return <IconEmptyData />;
      case '404':
        return <Icon404 />;
    }
  };

  return (
    <ErrorTemplateStyled>
      {renderIcon()}
      <H5>{text}</H5>
      {link && (
        <NavLink to={link.url}>
          <SecondaryButton
            size={'m'}
            leftIcon={{
              name: 'arrow-bold-left',
            }}
          >
            {link.text}
          </SecondaryButton>
        </NavLink>
      )}
    </ErrorTemplateStyled>
  );
};

export default ErrorTemplate;
