import { ethers } from 'ethers';
import {
  DRUG_MAX_SUPPLY,
  DRUGS,
  getTokenFromId,
  MOLECULE_MAX_SUPPLY,
  Token,
  Molecule,
} from '@faction-nfts/xsublimatio-smart-contracts';
import {
  XSublimatio,
  XSublimatio__factory,
} from '@faction-nfts/xsublimatio-smart-contracts/dist/esm/ethers';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { toast } from 'react-toastify';
import { makeAutoObservable } from 'mobx';

import type { RootStore } from 'stores/RootStore';
import projectContracts, { Projects } from 'contracts/projects';
import { DEFAULT_CHAIN_ID } from 'constants/chains';
import { INFURA_NETWORK_URLS } from 'constants/chainInfo';

import { Artwork, StoredDrug } from './XSublimatioStore';

const defaultNode = INFURA_NETWORK_URLS[DEFAULT_CHAIN_ID];
const bucketUrl = process.env.REACT_APP_XSUBLIMATIO_BUCKET as string;
const contractAddress = projectContracts[Projects.XSUBLIMATIO][DEFAULT_CHAIN_ID]
  ?.address as string;
const readJsonRpcProvider = new JsonRpcProvider(defaultNode);

export interface IList {
  name: string;
  totalSupply: number;
  maxSupply: number;
}

export interface IBagList {
  moleculesCountedList: Array<{ name: string; count: number }>;
  drugsCountedList: Array<{ name: string; count: number }>;
  totalDrugs: number;
  totalMolecules: number;
  totalTokens: number;
  molecules: Token[];
  drugs: Token[];
}

interface Recipe extends Molecule {
  count: number;
}

export interface IDrugComposition {
  name: string;
  recipe: Recipe[];
}

export class XSublimatioController {
  private rootStore: RootStore;

  public maxMolecules: number;

  public maxDrugs: number;

  public isBrewLoading: boolean;

  public isDrugCreated: boolean;

  public tokenId?: string;

  public createDrug?: StoredDrug;

  public contract: {
    read: XSublimatio;
    write?: (signer: JsonRpcSigner) => XSublimatio;
  } | null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.maxMolecules = MOLECULE_MAX_SUPPLY;
    this.maxDrugs = DRUG_MAX_SUPPLY;
    this.isBrewLoading = false;
    this.isDrugCreated = false;
    this.tokenId = undefined;
    this.contract = contractAddress
      ? {
          read: new Contract(
            contractAddress,
            XSublimatio__factory.abi,
            readJsonRpcProvider
          ) as XSublimatio,
          write: (signer: JsonRpcSigner) =>
            new Contract(
              contractAddress,
              XSublimatio__factory.abi,
              signer
            ) as XSublimatio,
        }
      : null;

    makeAutoObservable(this);
  }

  public setBrewLoading = (status: boolean): void => {
    this.isBrewLoading = status;
  };

  public setIsDrugCreated = (status: boolean): void => {
    this.isDrugCreated = status;
  };

  public setTokenId = (tokenId?: string): void => {
    this.tokenId = tokenId;
  };

  public isProjectLive = async (): Promise<boolean> => {
    if (!this.contract) return false;

    const launchTimestampBN = await this.contract?.read.LAUNCH_TIMESTAMP();
    const launchTimestamp = launchTimestampBN.toNumber();
    const currentTimestamp = Math.floor(Date.now() / 1000);

    return currentTimestamp > launchTimestamp;
  };

  public getTokensInBag = async (address: string): Promise<Token[]> => {
    if (!this.contract) return [];

    try {
      const tokenIds = await this.contract.read.tokensOfOwner(address);
      return tokenIds.map((id) =>
        getTokenFromId(
          id,
          'https://res.cloudinary.com/faction/image/upload/faction/xsublimatio/image',
          'https://res.cloudinary.com/faction/video/upload/faction/xsublimatio/video',
          'webp',
          'webm'
        )
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public getBagList = (data: Token[]): IBagList => {
    const molecules = data.filter((token) => token.category === 'molecule');
    const drugs = data.filter((token) => token.category === 'drug');

    const mapFn = (arr: Token[]) => {
      return arr.reduce(
        (acc: Array<{ name: string; count: number }>, curr: Token) => {
          const itemFound = acc.find((item) => item.name === curr.name);
          if (itemFound) {
            itemFound.count++;
            return acc;
          }

          return acc.concat({ name: curr.name, count: 1 });
        },
        []
      );
    };
    const moleculesCountedList = mapFn(molecules);

    const drugsCountedList = mapFn(drugs);

    const totalDrugs = drugs.length;
    const totalMolecules = molecules.length;
    const totalTokens = totalDrugs + totalMolecules;

    return {
      moleculesCountedList,
      drugsCountedList,
      totalDrugs,
      totalMolecules,
      totalTokens,
      molecules,
      drugs,
    };
  };

  public getPricePerMolecule = async (): Promise<string> => {
    if (!this.contract) return '0';

    try {
      const price = await this.contract.read.pricePerTokenMint();
      return ethers.utils.formatUnits(price, 'ether');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public decompose = async (tokenId: string) => {
    if (!this.contract) return;

    const account = this.rootStore.web3Store.address;
    const signer = this.rootStore.web3Store.provider?.getSigner();
    if (!this.contract.write || !account || !signer) {
      throw new Error('Wallet not connected');
    }
    if (this.rootStore.web3Store.chainId !== '0x1') {
      toast.error('Please switch to Ethereum Mainnet', {
        position: 'top-right',
      });
      throw new Error('Please switch to Ethereum Mainnet');
    }

    return this.contract.write(signer).decompose(tokenId);
  };

  public purchaseMolecules = async (amount = 1) => {
    if (!this.contract) return;

    const account = this.rootStore.web3Store.address;
    const signer = this.rootStore.web3Store.provider?.getSigner();
    if (!this.contract.write || !account || !signer) {
      throw new Error('Wallet not connected');
    }
    if (this.rootStore.web3Store.chainId !== '0x1') {
      toast.error('Please switch to Ethereum Mainnet', {
        position: 'top-right',
      });
      throw new Error('Please switch to Ethereum Mainnet');
    }

    try {
      const price = await this.contract.read.pricePerTokenMint();

      const gasEstimate = await this.contract
        .write(signer)
        .estimateGas.purchase(account, amount, 1, {
          value: price.mul(amount),
        });

      const res = await this.contract
        .write(signer)
        .purchase(account, amount, 1, {
          value: price.mul(amount),
          gasLimit: gasEstimate.mul(2),
        });

      toast.success('Success Purchase', {
        position: 'top-right',
      });
      return res;
    } catch (error) {
      console.error(error);
      toast.error('Error Purchase', {
        position: 'top-right',
      });
      throw error;
    }
  };

  public resetCreateDrug = () => {
    this.setTokenId(undefined);
    this.setIsDrugCreated(false);
    this.setBrewLoading(false);
    this.setCreateDrug(undefined);
  };

  public setCreateDrug = (drug?: StoredDrug) => {
    this.createDrug = drug;
  };

  public brewDrug = async (
    drug: StoredDrug,
    molecules: Token[]
  ): Promise<string> => {
    const account = this.rootStore.web3Store.address;
    const signer = this.rootStore.web3Store.provider?.getSigner();
    if (!this.contract?.write || !account || !signer) {
      throw new Error('Wallet not connected');
    }
    if (this.rootStore.web3Store.chainId !== '0x1') {
      toast.error('Please switch to Ethereum Mainnet', {
        position: 'top-right',
      });
      throw new Error('Please switch to Ethereum Mainnet');
    }

    try {
      const drugId = drug.drugId;
      const moleculeIds = molecules.map((molecule) => molecule.id);

      const gasEstimate = await this.contract
        .write(signer)
        .estimateGas.brew(moleculeIds, drugId, account);

      const tx = await this.contract
        .write(signer)
        .brew(moleculeIds, drugId, account, { gasLimit: gasEstimate.mul(2) });

      this.setBrewLoading(true);
      const receipt = await tx.wait();
      this.setBrewLoading(false);
      this.setIsDrugCreated(true);

      const receiptLastIndex = receipt.events!.length - 1;
      const tokenCreationEvent = receipt.events![receiptLastIndex];

      if (tokenCreationEvent?.args) {
        const [, , tokenId] = tokenCreationEvent?.args;

        this.setTokenId(tokenId.toString());
        toast.success('Success Brew', {
          position: 'top-right',
        });

        return tokenId.toString();
      }
      return '';
    } catch (error) {
      console.error(error);
      this.setBrewLoading(false);
      this.setIsDrugCreated(false);
      toast.error('Error Brew', {
        position: 'top-right',
      });
      throw error;
    }
  };

  public get getDrugComposition(): IDrugComposition[] {
    return DRUGS.map((drug) => {
      return {
        name: drug.name,
        recipe: drug.recipe.map((recipe) => ({ ...recipe, count: 1 })),
      };
    });
  }

  public claimFreeWater = () => {
    const account = this.rootStore.web3Store.address;
    const signer = this.rootStore.web3Store.provider?.getSigner();

    if (!this.contract || !this.contract.write || !account || !signer) {
      throw new Error('Wallet not connected');
    }
    if (this.rootStore.web3Store.chainId !== '0x1') {
      toast.error('Please switch to Ethereum Mainnet', {
        position: 'top-right',
      });
      throw new Error('Please switch to Ethereum Mainnet');
    }
    try {
      return this.contract.write(signer).claimWater(account);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  public canClaimFreeWater = async (): Promise<boolean> => {
    const account = this.rootStore.web3Store.address;
    if (!account) {
      throw new Error('Wallet not connected');
    }
    try {
      const isCanClaim = await this.contract?.read.canClaimFreeWater(account);
      return Boolean(isCanClaim);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public getDrugComponents = async (tokenId: string): Promise<Artwork[]> => {
    const bnMolecules =
      (await this.contract?.read.getMoleculesWithinDrug(tokenId)) || [];

    const moleculeIds = bnMolecules?.map((bn) => bn.toString());

    const artworks = moleculeIds.map((moleculeId) =>
      this.rootStore.xsublimatioStore.artworks.get(moleculeId)
    );

    const filteredArtworks: Artwork[] = artworks.filter(
      (val) => val !== undefined
    ) as unknown as Artwork[];

    return filteredArtworks;
  };

  getMoleculeParent = async (tokenId: string): Promise<Artwork> => {
    try {
      const res = await this.contract?.read.getDrugContainingMolecule(tokenId);

      if (!res) throw new Error('getDrugContainingMolecule undefined');

      const artwork = this.rootStore.xsublimatioStore.artworks.get(
        res.toString()
      );

      if (!artwork) {
        throw new Error('artwork not found');
      }

      return artwork;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}
