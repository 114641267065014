import styled from 'styled-components/macro';

import Modal from '../Modal';

export const CreateNftCardStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  border: 0.5px solid ${(props) => props.theme.colors.primary[4]};
  border-radius: 10px;
  transition: transform 0.4s, box-shadow 0.4s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 4px 4px ${(props) => props.theme.colors.primary[26]};
  }
`;

export const ButtonWrapper = styled.div`
  > * {
    width: 100%;
  }
`;

export const Border = styled.div`
  width: 100%;
  height: 0px;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.primary[4]};
`;

export const ImageWrapperStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleSectionStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  font-size: 20px;
  font-weight: 500;
`;

export const SoldOutTextStyled = styled.div`
  color: ${(props) => props.theme.colors.primary[9]};
  position: absolute;
  font-weight: 900;
  font-size: 36px;
`;

export const DrugMakeupStyled = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const TextStyled = styled.div`
  position: relative;
  font-size: 20px;
  color: ${(props) => props.theme.colors.primary[4]};
`;

export const InfoWrapperStyled = styled.div`
  position: absolute;
  top: -5px;
  right: -20px;
  color: ${(props) => props.theme.colors.primary[4]};
  cursor: pointer;
  font-size: 0;
  line-height: 0;
  transition: color 0.25s ease;
  &:hover {
    color: ${(props) => props.theme.colors.primary[3]};
  }
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const StyledModal = styled(Modal)`
  width: 100%;
`;

export const InfoSectionStyled = styled.div`
  position: relative;
`;
