export const ALLOWED_PROJECTS = ['Xsublimatio', 'Stencils'];

export const PROJECTS = ['xsublimatio', 'stencils', 'd-lives', 'ceramica'];

export const PATHS = {
  HOME: '/',
  ARTWORK: {
    INDEX: 'artwork/:projectType/:tokenId',
    VIDEO: 'video',
  },
  STENCILS_PROJECT: {
    INDEX: 'project/stencils',
    NFT_CATALOG: 'nft-catalog',
    MY_BAG: 'my-bag',
  },
  PROJECT: {
    INDEX: 'project/:projectType',
    CREATE_DRUG: {
      INDEX: 'create-drug',
      ALL: 'all',
      AVAILABLE: 'available',
    },
    NFT_CATALOG: {
      INDEX: 'nft-catalog',
      TEXT: 'text',
      VISUAL: 'visual',
    },
    MY_BAG: {
      INDEX: 'my-bag',
      TEXT: 'text',
      VISUAL: {
        INDEX: 'visual',
        DRUGS: 'drugs',
        MOLECULES: 'molecules',
      },
    },
    // RECIPES: {
    //   INDEX: 'recipes',
    //   DRUGS: 'drugs',
    //   MOLECULES: 'molecules',
    // },
  },
  FEATURED: {
    INDEX: 'featured',
    LIVE: 'live',
    UPCOMING: 'upcoming',
    ALL: 'all',
  },
  DAO: {
    INDEX: 'dao',
    MEMBERS: 'members',
    MANIFESTO: 'manifesto',
    DIAGRAM: 'diagram',
    METRICS: 'metrics',
    PROPOSALS: 'proposals',
  },
  USER: {
    INDEX: 'user',
    COLLECTED: ':walletAddress/collected',
    FAVORITES: ':walletAddress/favorites',
    PROJECTS: ':walletAddress/projects',
    VOTES: ':walletAddress/votes',
    SETTINGS: ':walletAddress/settings',
  },
  ABOUT: 'about',
  PRIVACY: 'privacy',
  TERMS: 'terms',

  NOT_FOUND: '*',
};

export const LINKS = {
  HOME: '/',
  ARTWORK: (projectType: string, tokenId: string) => ({
    INDEX: `/artwork/${projectType}/${tokenId}`,
    VIDEO: `/artwork/${projectType}/${tokenId}/video`,
  }),

  STENCILS_PROJECT: {
    INDEX: '/project/stencils',
    NFT_CATALOG: '/project/stencils/nft-catalog',
    MY_BAG: '/project/stencils/my-bag',
  },

  PROJECT: (project: string) => ({
    INDEX: `/project/${project}`,
    CREATE_DRUG: {
      INDEX: `/project/${project}/create-drug`,
      ALL: `/project/${project}/create-drug/all`,
      AVAILABLE: `/project/${project}/create-drug/available`,
    },
    NFT_CATALOG: {
      INDEX: `/project/${project}/nft-catalog`,
      VISUAL: `/project/${project}/nft-catalog/visual`,
      TEXT: `/project/${project}/nft-catalog/text`,
    },
    MY_BAG: {
      INDEX: `/project/${project}/my-bag`,
      TEXT: `/project/${project}/my-bag/text`,
      VISUAL: {
        INDEX: `/project/${project}/my-bag/visual`,
        MOLECULES: `/project/${project}/my-bag/visual/molecules`,
        DRUGS: `/project/${project}/my-bag/visual/drugs`,
      },
    },
    RECIPES: {
      INDEX: `/project/${project}/recipes`,
      MOLECULES: `/project/${project}/recipes/molecules`,
      DRUGS: `/project/${project}/recipes/drugs`,
    },
  }),
  FEATURED: {
    INDEX: '/featured',
    LIVE: '/featured/live',
    UPCOMING: '/featured/upcoming',
    ALL: '/featured/all',
  },
  DAO: {
    INDEX: '/dao',
    MEMBERS: '/dao/members',
    MANIFESTO: '/dao/manifesto',
    DIAGRAM: '/dao/diagram',
    METRICS: '/dao/metrics',
    PROPOSALS: '/dao/proposals',
  },
  USER: (walletAddress?: string) => ({
    INDEX: `/user`,
    COLLECTED: `/user/${walletAddress}/collected`,
    FAVORITES: `/user/${walletAddress}/favorites`,
    PROJECTS: `/user/${walletAddress}/projects`,
    VOTES: `/user/${walletAddress}/votes`,
    SETTINGS: `/user/${walletAddress}/settings`,
  }),
  ABOUT: '/about',
  TERMS: '/terms',
  PRIVACY: '/privacy',
};
