import { SpinnerStyled } from './styles';

interface SpinnerProps {
  size?: number;
}

const Spinner = (props: SpinnerProps): JSX.Element => {
  const { size = 100 } = props;
  return (
    <SpinnerStyled
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <svg viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          strokeWidth="2"
          fill={'none'}
        />
      </svg>
    </SpinnerStyled>
  );
};

export default Spinner;
