import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import CircleButton, { CircleButtonProps } from './CircleButton';

const SecondaryCircleButtonStyled = styled(CircleButton)`
  position: relative;
  background: transparent;
  ${({ theme }) => css`
    background: ${theme.colors.primary[17]};
    color: ${theme.colors.primary[9]};

    &:disabled {
      color: ${theme.colors.primary[9]};
      background: ${theme.colors.primary[13]};
    }

    &:active,
    &:hover {
      background: ${theme.colors.primary[22]};
      color: ${theme.colors.primary[9]};
    }
  `}
`;

const SecondaryCircleButton: FC<CircleButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <SecondaryCircleButtonStyled {...rest}>
      {children}
    </SecondaryCircleButtonStyled>
  );
};

export default SecondaryCircleButton;
