import { Project } from 'api/generated/graphql';

const isLive = (project: Project) =>
  !!(
    project.launchDate &&
    project.type &&
    new Date(project.launchDate).getTime() < new Date().getTime()
  );

export default isLive;
