import React, { FC } from 'react';

import styled, { css, DefaultTheme } from 'styled-components/macro';

import { Icon, TIconName } from 'modules/components/Icon';

import BaseButton, { BaseButtonProps } from './BaseButton';

type TSize = 'xs' | 's' | 'm' | 'l' | 'xl';
type TBorderRadius = 'pill' | 'round';
type TTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'none';

export interface IIcon {
  name: TIconName;
  width?: number;
  height?: number;
}

export interface ButtonProps extends BaseButtonProps {
  leftIcon?: IIcon;
  rightIcon?: IIcon;
  size?: TSize;
  border?: TBorderRadius;
  textTransform?: TTransform;
  textUnderline?: boolean;
  fluid?: boolean;
  shadow?: boolean;
  fixed?: boolean;
  indent?: boolean;
  fontWeight?: number;
  loader?: boolean;
  isActive?: boolean;
}

const TextStyled = styled.span<{
  size: TSize;
  textTransform: TTransform;
  textUnderline: boolean;
  fontWeight?: number;
}>`
  color: currentColor;
  z-index: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme, textTransform, size, textUnderline, fontWeight }) => css`
    font-size: ${theme.buttons.main.size[size].font.fontSize};
    font-weight: ${fontWeight || theme.buttons.main.size[size].font.fontWeight};
    line-height: ${theme.buttons.main.size[size].font.lineHeight};
    text-decoration-line: ${textUnderline ? 'underline' : 'none'};
    text-transform: ${textTransform};
  `}
`;

const getSizeButtonStyles = (props: {
  theme: DefaultTheme;
  size: TSize;
  fluid: boolean;
  fixed: boolean;
  indent: boolean;
}) => {
  const { theme, size, fluid, fixed, indent } = props;
  if (fixed) {
    return css`
      width: ${fluid ? `100%` : theme.buttons.main.size[size].width};
      height: ${theme.buttons.main.size[size].height};
    `;
  }

  return css`
    width: ${fluid ? `100%` : `auto`};
    padding: ${indent ? theme.buttons.main.size[size].indent : 0};
  `;
};

const ButtonStyled = styled(BaseButton)<{
  size: TSize;
  border: TBorderRadius;
  fluid: boolean;
  shadow: boolean;
  fixed: boolean;
  indent: boolean;
}>`
  border: none;
  ${({ theme, fluid, border, size, shadow, fixed, indent }) => css`
    ${getSizeButtonStyles({ theme, size, fluid, fixed, indent })}
    border-radius: ${theme.radii[border]};
    box-shadow: ${shadow ? theme.buttons.main.shadow : 'none'};
  `}
`;

const IconWrapperStyles = css`
  color: currentColor;
  line-height: 0;
  font-size: 0;
  z-index: 2;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const IconLeftWrapper = styled.span`
  ${IconWrapperStyles};
  margin-right: 5px;
`;

const IconRightWrapper = styled.span`
  ${IconWrapperStyles};
  margin-left: 5px;
`;

const LoaderWrapperStyled = styled.span`
  ${IconWrapperStyles};
  svg {
    width: 34px;
    height: 34px;
  }
`;

const Button: FC<ButtonProps> = (props) => {
  const {
    children,
    leftIcon,
    rightIcon,
    size = 'm',
    border = 'round',
    textTransform = 'uppercase',
    fluid = false,
    shadow = false,
    fixed = true,
    textUnderline = false,
    fontWeight,
    loader = false,
    indent = true,
    ...rest
  } = props;

  const renderLoader = () => {
    return (
      <LoaderWrapperStyled>
        <Icon name={'refresh'} />
      </LoaderWrapperStyled>
    );
  };

  const renderBody = () => {
    return (
      <>
        {leftIcon && (
          <IconLeftWrapper
            style={{
              width: `${leftIcon.width || 24}px`,
              height: `${leftIcon.height || 24}px`,
            }}
          >
            <Icon name={leftIcon.name} />
          </IconLeftWrapper>
        )}

        <TextStyled
          fontWeight={fontWeight}
          size={size}
          textTransform={textTransform}
          textUnderline={textUnderline}
        >
          {children}
        </TextStyled>

        {rightIcon && (
          <IconRightWrapper
            style={{
              width: `${rightIcon.width || 24}px`,
              height: `${rightIcon.height || 24}px`,
            }}
          >
            <Icon name={rightIcon.name} />
          </IconRightWrapper>
        )}
      </>
    );
  };

  return (
    <ButtonStyled
      {...rest}
      size={size}
      border={border}
      fluid={fluid}
      shadow={shadow}
      fixed={fixed}
      indent={indent}
    >
      {loader ? renderLoader() : renderBody()}
    </ButtonStyled>
  );
};

export default Button;
