export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AdminUser = {
  __typename?: 'AdminUser';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  password?: Maybe<PasswordState>;
};

export type AdminUserAuthenticationWithPasswordFailure = {
  __typename?: 'AdminUserAuthenticationWithPasswordFailure';
  message: Scalars['String'];
};

export type AdminUserAuthenticationWithPasswordResult =
  | AdminUserAuthenticationWithPasswordFailure
  | AdminUserAuthenticationWithPasswordSuccess;

export type AdminUserAuthenticationWithPasswordSuccess = {
  __typename?: 'AdminUserAuthenticationWithPasswordSuccess';
  item: AdminUser;
  sessionToken: Scalars['String'];
};

export type AdminUserCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type AdminUserOrderByInput = {
  email?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type AdminUserUpdateArgs = {
  data: AdminUserUpdateInput;
  where: AdminUserWhereUniqueInput;
};

export type AdminUserUpdateInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type AdminUserWhereInput = {
  AND?: InputMaybe<Array<AdminUserWhereInput>>;
  NOT?: InputMaybe<Array<AdminUserWhereInput>>;
  OR?: InputMaybe<Array<AdminUserWhereInput>>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
};

export type AdminUserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type Artwork = {
  __typename?: 'Artwork';
  animationUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  loadedMetadata?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  placeholderImageUrl?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  uniqueId?: Maybe<Scalars['String']>;
};

export type ArtworkCreateInput = {
  animationUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  loadedMetadata?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserRelateToOneForCreateInput>;
  placeholderImageUrl?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<ProjectRelateToOneForCreateInput>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type ArtworkManyRelationFilter = {
  every?: InputMaybe<ArtworkWhereInput>;
  none?: InputMaybe<ArtworkWhereInput>;
  some?: InputMaybe<ArtworkWhereInput>;
};

export type ArtworkOrderByInput = {
  animationUrl?: InputMaybe<OrderDirection>;
  createdAt?: InputMaybe<OrderDirection>;
  description?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  imageUrl?: InputMaybe<OrderDirection>;
  loadedMetadata?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  placeholderImageUrl?: InputMaybe<OrderDirection>;
  thumbnailUrl?: InputMaybe<OrderDirection>;
  tokenId?: InputMaybe<OrderDirection>;
  uniqueId?: InputMaybe<OrderDirection>;
};

export type ArtworkRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ArtworkWhereUniqueInput>>;
  create?: InputMaybe<Array<ArtworkCreateInput>>;
};

export type ArtworkRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ArtworkWhereUniqueInput>>;
  create?: InputMaybe<Array<ArtworkCreateInput>>;
  disconnect?: InputMaybe<Array<ArtworkWhereUniqueInput>>;
  set?: InputMaybe<Array<ArtworkWhereUniqueInput>>;
};

export type ArtworkUpdateArgs = {
  data: ArtworkUpdateInput;
  where: ArtworkWhereUniqueInput;
};

export type ArtworkUpdateInput = {
  animationUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  loadedMetadata?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserRelateToOneForUpdateInput>;
  placeholderImageUrl?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<ProjectRelateToOneForUpdateInput>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  tokenId?: InputMaybe<Scalars['String']>;
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type ArtworkWhereInput = {
  AND?: InputMaybe<Array<ArtworkWhereInput>>;
  NOT?: InputMaybe<Array<ArtworkWhereInput>>;
  OR?: InputMaybe<Array<ArtworkWhereInput>>;
  animationUrl?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  imageUrl?: InputMaybe<StringFilter>;
  loadedMetadata?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserWhereInput>;
  placeholderImageUrl?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectWhereInput>;
  thumbnailUrl?: InputMaybe<StringFilter>;
  tokenId?: InputMaybe<StringFilter>;
  uniqueId?: InputMaybe<StringFilter>;
};

export type ArtworkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  uniqueId?: InputMaybe<Scalars['String']>;
};

export type AuthenticatedItem = AdminUser;

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilter>;
};

/**
 * Mirrors the formatting options [Cloudinary provides](https://cloudinary.com/documentation/image_transformation_reference).
 * All options are strings as they ultimately end up in a URL.
 */
export type CloudinaryImageFormat = {
  angle?: InputMaybe<Scalars['String']>;
  aspect_ratio?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<Scalars['String']>;
  border?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
  color_space?: InputMaybe<Scalars['String']>;
  crop?: InputMaybe<Scalars['String']>;
  default_image?: InputMaybe<Scalars['String']>;
  delay?: InputMaybe<Scalars['String']>;
  density?: InputMaybe<Scalars['String']>;
  dpr?: InputMaybe<Scalars['String']>;
  effect?: InputMaybe<Scalars['String']>;
  fetch_format?: InputMaybe<Scalars['String']>;
  flags?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  gravity?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  opacity?: InputMaybe<Scalars['String']>;
  overlay?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['String']>;
  /** Rewrites the filename to be this pretty string. Do not include `/` or `.` */
  prettyName?: InputMaybe<Scalars['String']>;
  quality?: InputMaybe<Scalars['String']>;
  radius?: InputMaybe<Scalars['String']>;
  transformation?: InputMaybe<Scalars['String']>;
  underlay?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['String']>;
  x?: InputMaybe<Scalars['String']>;
  y?: InputMaybe<Scalars['String']>;
  zoom?: InputMaybe<Scalars['String']>;
};

export type CloudinaryImage_File = {
  __typename?: 'CloudinaryImage_File';
  encoding?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  mimetype?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  publicUrlTransformed?: Maybe<Scalars['String']>;
};

export type CloudinaryImage_FilePublicUrlTransformedArgs = {
  transformation?: InputMaybe<CloudinaryImageFormat>;
};

export type CreateInitialAdminUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type ExampleArtwork = {
  __typename?: 'ExampleArtwork';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  image?: Maybe<CloudinaryImage_File>;
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type ExampleArtworkCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<Scalars['Upload']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<ProjectRelateToOneForCreateInput>;
};

export type ExampleArtworkManyRelationFilter = {
  every?: InputMaybe<ExampleArtworkWhereInput>;
  none?: InputMaybe<ExampleArtworkWhereInput>;
  some?: InputMaybe<ExampleArtworkWhereInput>;
};

export type ExampleArtworkOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
};

export type ExampleArtworkRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ExampleArtworkWhereUniqueInput>>;
  create?: InputMaybe<Array<ExampleArtworkCreateInput>>;
};

export type ExampleArtworkRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ExampleArtworkWhereUniqueInput>>;
  create?: InputMaybe<Array<ExampleArtworkCreateInput>>;
  disconnect?: InputMaybe<Array<ExampleArtworkWhereUniqueInput>>;
  set?: InputMaybe<Array<ExampleArtworkWhereUniqueInput>>;
};

export type ExampleArtworkUpdateArgs = {
  data: ExampleArtworkUpdateInput;
  where: ExampleArtworkWhereUniqueInput;
};

export type ExampleArtworkUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  image?: InputMaybe<Scalars['Upload']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<ProjectRelateToOneForUpdateInput>;
};

export type ExampleArtworkWhereInput = {
  AND?: InputMaybe<Array<ExampleArtworkWhereInput>>;
  NOT?: InputMaybe<Array<ExampleArtworkWhereInput>>;
  OR?: InputMaybe<Array<ExampleArtworkWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  project?: InputMaybe<ProjectWhereInput>;
};

export type ExampleArtworkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type IdFilter = {
  equals?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilter>;
  notIn?: InputMaybe<Array<Scalars['ID']>>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type KeystoneAdminMeta = {
  __typename?: 'KeystoneAdminMeta';
  enableSessionItem: Scalars['Boolean'];
  enableSignout: Scalars['Boolean'];
  list?: Maybe<KeystoneAdminUiListMeta>;
  lists: Array<KeystoneAdminUiListMeta>;
};

export type KeystoneAdminMetaListArgs = {
  key: Scalars['String'];
};

export type KeystoneAdminUiFieldMeta = {
  __typename?: 'KeystoneAdminUIFieldMeta';
  createView: KeystoneAdminUiFieldMetaCreateView;
  customViewsIndex?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  fieldMeta?: Maybe<Scalars['JSON']>;
  isFilterable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
  label: Scalars['String'];
  listView: KeystoneAdminUiFieldMetaListView;
  path: Scalars['String'];
  search?: Maybe<QueryMode>;
  viewsIndex: Scalars['Int'];
};

export type KeystoneAdminUiFieldMetaItemViewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type KeystoneAdminUiFieldMetaCreateView = {
  __typename?: 'KeystoneAdminUIFieldMetaCreateView';
  fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden',
}

export type KeystoneAdminUiFieldMetaItemView = {
  __typename?: 'KeystoneAdminUIFieldMetaItemView';
  fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden',
  Read = 'read',
}

export type KeystoneAdminUiFieldMetaListView = {
  __typename?: 'KeystoneAdminUIFieldMetaListView';
  fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  Hidden = 'hidden',
  Read = 'read',
}

export type KeystoneAdminUiListMeta = {
  __typename?: 'KeystoneAdminUIListMeta';
  description?: Maybe<Scalars['String']>;
  fields: Array<KeystoneAdminUiFieldMeta>;
  hideCreate: Scalars['Boolean'];
  hideDelete: Scalars['Boolean'];
  initialColumns: Array<Scalars['String']>;
  initialSort?: Maybe<KeystoneAdminUiSort>;
  isHidden: Scalars['Boolean'];
  itemQueryName: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  labelField: Scalars['String'];
  listQueryName: Scalars['String'];
  pageSize: Scalars['Int'];
  path: Scalars['String'];
  plural: Scalars['String'];
  singular: Scalars['String'];
};

export type KeystoneAdminUiSort = {
  __typename?: 'KeystoneAdminUISort';
  direction: KeystoneAdminUiSortDirection;
  field: Scalars['String'];
};

export enum KeystoneAdminUiSortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type KeystoneMeta = {
  __typename?: 'KeystoneMeta';
  adminMeta: KeystoneAdminMeta;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticateAdminUserWithPassword?: Maybe<AdminUserAuthenticationWithPasswordResult>;
  createAdminUser?: Maybe<AdminUser>;
  createAdminUsers?: Maybe<Array<Maybe<AdminUser>>>;
  createArtwork?: Maybe<Artwork>;
  createArtworks?: Maybe<Array<Maybe<Artwork>>>;
  createExampleArtwork?: Maybe<ExampleArtwork>;
  createExampleArtworks?: Maybe<Array<Maybe<ExampleArtwork>>>;
  createInitialAdminUser: AdminUserAuthenticationWithPasswordSuccess;
  createProject?: Maybe<Project>;
  createProjects?: Maybe<Array<Maybe<Project>>>;
  createUser?: Maybe<User>;
  createUsers?: Maybe<Array<Maybe<User>>>;
  deleteAdminUser?: Maybe<AdminUser>;
  deleteAdminUsers?: Maybe<Array<Maybe<AdminUser>>>;
  deleteArtwork?: Maybe<Artwork>;
  deleteArtworks?: Maybe<Array<Maybe<Artwork>>>;
  deleteExampleArtwork?: Maybe<ExampleArtwork>;
  deleteExampleArtworks?: Maybe<Array<Maybe<ExampleArtwork>>>;
  deleteProject?: Maybe<Project>;
  deleteProjects?: Maybe<Array<Maybe<Project>>>;
  deleteUser?: Maybe<User>;
  deleteUsers?: Maybe<Array<Maybe<User>>>;
  endSession: Scalars['Boolean'];
  updateAdminUser?: Maybe<AdminUser>;
  updateAdminUsers?: Maybe<Array<Maybe<AdminUser>>>;
  updateArtwork?: Maybe<Artwork>;
  updateArtworks?: Maybe<Array<Maybe<Artwork>>>;
  updateExampleArtwork?: Maybe<ExampleArtwork>;
  updateExampleArtworks?: Maybe<Array<Maybe<ExampleArtwork>>>;
  updateProject?: Maybe<Project>;
  updateProjects?: Maybe<Array<Maybe<Project>>>;
  updateUser?: Maybe<User>;
  updateUsers?: Maybe<Array<Maybe<User>>>;
};

export type MutationAuthenticateAdminUserWithPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationCreateAdminUserArgs = {
  data: AdminUserCreateInput;
};

export type MutationCreateAdminUsersArgs = {
  data: Array<AdminUserCreateInput>;
};

export type MutationCreateArtworkArgs = {
  data: ArtworkCreateInput;
};

export type MutationCreateArtworksArgs = {
  data: Array<ArtworkCreateInput>;
};

export type MutationCreateExampleArtworkArgs = {
  data: ExampleArtworkCreateInput;
};

export type MutationCreateExampleArtworksArgs = {
  data: Array<ExampleArtworkCreateInput>;
};

export type MutationCreateInitialAdminUserArgs = {
  data: CreateInitialAdminUserInput;
};

export type MutationCreateProjectArgs = {
  data: ProjectCreateInput;
};

export type MutationCreateProjectsArgs = {
  data: Array<ProjectCreateInput>;
};

export type MutationCreateUserArgs = {
  data: UserCreateInput;
};

export type MutationCreateUsersArgs = {
  data: Array<UserCreateInput>;
};

export type MutationDeleteAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};

export type MutationDeleteAdminUsersArgs = {
  where: Array<AdminUserWhereUniqueInput>;
};

export type MutationDeleteArtworkArgs = {
  where: ArtworkWhereUniqueInput;
};

export type MutationDeleteArtworksArgs = {
  where: Array<ArtworkWhereUniqueInput>;
};

export type MutationDeleteExampleArtworkArgs = {
  where: ExampleArtworkWhereUniqueInput;
};

export type MutationDeleteExampleArtworksArgs = {
  where: Array<ExampleArtworkWhereUniqueInput>;
};

export type MutationDeleteProjectArgs = {
  where: ProjectWhereUniqueInput;
};

export type MutationDeleteProjectsArgs = {
  where: Array<ProjectWhereUniqueInput>;
};

export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};

export type MutationDeleteUsersArgs = {
  where: Array<UserWhereUniqueInput>;
};

export type MutationUpdateAdminUserArgs = {
  data: AdminUserUpdateInput;
  where: AdminUserWhereUniqueInput;
};

export type MutationUpdateAdminUsersArgs = {
  data: Array<AdminUserUpdateArgs>;
};

export type MutationUpdateArtworkArgs = {
  data: ArtworkUpdateInput;
  where: ArtworkWhereUniqueInput;
};

export type MutationUpdateArtworksArgs = {
  data: Array<ArtworkUpdateArgs>;
};

export type MutationUpdateExampleArtworkArgs = {
  data: ExampleArtworkUpdateInput;
  where: ExampleArtworkWhereUniqueInput;
};

export type MutationUpdateExampleArtworksArgs = {
  data: Array<ExampleArtworkUpdateArgs>;
};

export type MutationUpdateProjectArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};

export type MutationUpdateProjectsArgs = {
  data: Array<ProjectUpdateArgs>;
};

export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpdateUsersArgs = {
  data: Array<UserUpdateArgs>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export type PasswordState = {
  __typename?: 'PasswordState';
  isSet: Scalars['Boolean'];
};

export type Project = {
  __typename?: 'Project';
  address?: Maybe<Scalars['String']>;
  artist?: Maybe<User>;
  artistName?: Maybe<Scalars['String']>;
  artworks?: Maybe<Array<Artwork>>;
  artworksCount?: Maybe<Scalars['Int']>;
  exampleArtworks?: Maybe<Array<ExampleArtwork>>;
  exampleArtworksCount?: Maybe<Scalars['Int']>;
  featuredOnHomepage?: Maybe<Scalars['Boolean']>;
  homeFeaturedArtwork?: Maybe<CloudinaryImage_File>;
  homepageWeight?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  lastSyncedBlock?: Maybe<Scalars['Int']>;
  launchDate?: Maybe<Scalars['DateTime']>;
  metadataUri?: Maybe<Scalars['String']>;
  mintLimit?: Maybe<Scalars['Int']>;
  mintType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ProjectTypeType>;
};

export type ProjectArtworksArgs = {
  orderBy?: Array<ArtworkOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ArtworkWhereInput;
};

export type ProjectArtworksCountArgs = {
  where?: ArtworkWhereInput;
};

export type ProjectExampleArtworksArgs = {
  orderBy?: Array<ExampleArtworkOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ExampleArtworkWhereInput;
};

export type ProjectExampleArtworksCountArgs = {
  where?: ExampleArtworkWhereInput;
};

export type ProjectCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  artist?: InputMaybe<UserRelateToOneForCreateInput>;
  artistName?: InputMaybe<Scalars['String']>;
  artworks?: InputMaybe<ArtworkRelateToManyForCreateInput>;
  exampleArtworks?: InputMaybe<ExampleArtworkRelateToManyForCreateInput>;
  featuredOnHomepage?: InputMaybe<Scalars['Boolean']>;
  homeFeaturedArtwork?: InputMaybe<Scalars['Upload']>;
  homepageWeight?: InputMaybe<Scalars['Int']>;
  lastSyncedBlock?: InputMaybe<Scalars['Int']>;
  launchDate?: InputMaybe<Scalars['DateTime']>;
  metadataUri?: InputMaybe<Scalars['String']>;
  mintLimit?: InputMaybe<Scalars['Int']>;
  mintType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProjectTypeType>;
};

export type ProjectManyRelationFilter = {
  every?: InputMaybe<ProjectWhereInput>;
  none?: InputMaybe<ProjectWhereInput>;
  some?: InputMaybe<ProjectWhereInput>;
};

export type ProjectOrderByInput = {
  address?: InputMaybe<OrderDirection>;
  artistName?: InputMaybe<OrderDirection>;
  featuredOnHomepage?: InputMaybe<OrderDirection>;
  homepageWeight?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  lastSyncedBlock?: InputMaybe<OrderDirection>;
  launchDate?: InputMaybe<OrderDirection>;
  metadataUri?: InputMaybe<OrderDirection>;
  mintLimit?: InputMaybe<OrderDirection>;
  mintType?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  type?: InputMaybe<OrderDirection>;
};

export type ProjectRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  create?: InputMaybe<Array<ProjectCreateInput>>;
};

export type ProjectRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  create?: InputMaybe<Array<ProjectCreateInput>>;
  disconnect?: InputMaybe<Array<ProjectWhereUniqueInput>>;
  set?: InputMaybe<Array<ProjectWhereUniqueInput>>;
};

export type ProjectRelateToOneForCreateInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  create?: InputMaybe<ProjectCreateInput>;
};

export type ProjectRelateToOneForUpdateInput = {
  connect?: InputMaybe<ProjectWhereUniqueInput>;
  create?: InputMaybe<ProjectCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export enum ProjectTypeType {
  Stencils = 'stencils',
  Xsublimatio = 'xsublimatio',
}

export type ProjectTypeTypeNullableFilter = {
  equals?: InputMaybe<ProjectTypeType>;
  in?: InputMaybe<Array<ProjectTypeType>>;
  not?: InputMaybe<ProjectTypeTypeNullableFilter>;
  notIn?: InputMaybe<Array<ProjectTypeType>>;
};

export type ProjectUpdateArgs = {
  data: ProjectUpdateInput;
  where: ProjectWhereUniqueInput;
};

export type ProjectUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  artist?: InputMaybe<UserRelateToOneForUpdateInput>;
  artistName?: InputMaybe<Scalars['String']>;
  artworks?: InputMaybe<ArtworkRelateToManyForUpdateInput>;
  exampleArtworks?: InputMaybe<ExampleArtworkRelateToManyForUpdateInput>;
  featuredOnHomepage?: InputMaybe<Scalars['Boolean']>;
  homeFeaturedArtwork?: InputMaybe<Scalars['Upload']>;
  homepageWeight?: InputMaybe<Scalars['Int']>;
  lastSyncedBlock?: InputMaybe<Scalars['Int']>;
  launchDate?: InputMaybe<Scalars['DateTime']>;
  metadataUri?: InputMaybe<Scalars['String']>;
  mintLimit?: InputMaybe<Scalars['Int']>;
  mintType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ProjectTypeType>;
};

export type ProjectWhereInput = {
  AND?: InputMaybe<Array<ProjectWhereInput>>;
  NOT?: InputMaybe<Array<ProjectWhereInput>>;
  OR?: InputMaybe<Array<ProjectWhereInput>>;
  address?: InputMaybe<StringFilter>;
  artist?: InputMaybe<UserWhereInput>;
  artistName?: InputMaybe<StringFilter>;
  artworks?: InputMaybe<ArtworkManyRelationFilter>;
  exampleArtworks?: InputMaybe<ExampleArtworkManyRelationFilter>;
  featuredOnHomepage?: InputMaybe<BooleanFilter>;
  homepageWeight?: InputMaybe<IntFilter>;
  id?: InputMaybe<IdFilter>;
  lastSyncedBlock?: InputMaybe<IntFilter>;
  launchDate?: InputMaybe<DateTimeNullableFilter>;
  metadataUri?: InputMaybe<StringNullableFilter>;
  mintLimit?: InputMaybe<IntNullableFilter>;
  mintType?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<ProjectTypeTypeNullableFilter>;
};

export type ProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<ProjectTypeType>;
};

export type Query = {
  __typename?: 'Query';
  adminUser?: Maybe<AdminUser>;
  adminUsers?: Maybe<Array<AdminUser>>;
  adminUsersCount?: Maybe<Scalars['Int']>;
  artwork?: Maybe<Artwork>;
  artworks?: Maybe<Array<Artwork>>;
  artworksCount?: Maybe<Scalars['Int']>;
  authenticatedItem?: Maybe<AuthenticatedItem>;
  exampleArtwork?: Maybe<ExampleArtwork>;
  exampleArtworks?: Maybe<Array<ExampleArtwork>>;
  exampleArtworksCount?: Maybe<Scalars['Int']>;
  keystone: KeystoneMeta;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Project>>;
  projectsCount?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  users?: Maybe<Array<User>>;
  usersCount?: Maybe<Scalars['Int']>;
};

export type QueryAdminUserArgs = {
  where: AdminUserWhereUniqueInput;
};

export type QueryAdminUsersArgs = {
  orderBy?: Array<AdminUserOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: AdminUserWhereInput;
};

export type QueryAdminUsersCountArgs = {
  where?: AdminUserWhereInput;
};

export type QueryArtworkArgs = {
  where: ArtworkWhereUniqueInput;
};

export type QueryArtworksArgs = {
  orderBy?: Array<ArtworkOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ArtworkWhereInput;
};

export type QueryArtworksCountArgs = {
  where?: ArtworkWhereInput;
};

export type QueryExampleArtworkArgs = {
  where: ExampleArtworkWhereUniqueInput;
};

export type QueryExampleArtworksArgs = {
  orderBy?: Array<ExampleArtworkOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ExampleArtworkWhereInput;
};

export type QueryExampleArtworksCountArgs = {
  where?: ExampleArtworkWhereInput;
};

export type QueryProjectArgs = {
  where: ProjectWhereUniqueInput;
};

export type QueryProjectsArgs = {
  orderBy?: Array<ProjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ProjectWhereInput;
};

export type QueryProjectsCountArgs = {
  where?: ProjectWhereInput;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  orderBy?: Array<UserOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: UserWhereInput;
};

export type QueryUsersCountArgs = {
  where?: UserWhereInput;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']>;
  artworks?: Maybe<Array<Artwork>>;
  artworksCount?: Maybe<Scalars['Int']>;
  bio?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  isMember?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['Int']>;
  overrideSignature?: Maybe<Scalars['Boolean']>;
  profileImage?: Maybe<CloudinaryImage_File>;
  projects?: Maybe<Array<Project>>;
  projectsCount?: Maybe<Scalars['Int']>;
  signature?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UserArtworksArgs = {
  orderBy?: Array<ArtworkOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ArtworkWhereInput;
};

export type UserArtworksCountArgs = {
  where?: ArtworkWhereInput;
};

export type UserProjectsArgs = {
  orderBy?: Array<ProjectOrderByInput>;
  skip?: Scalars['Int'];
  take?: InputMaybe<Scalars['Int']>;
  where?: ProjectWhereInput;
};

export type UserProjectsCountArgs = {
  where?: ProjectWhereInput;
};

export type UserCreateInput = {
  address?: InputMaybe<Scalars['String']>;
  artworks?: InputMaybe<ArtworkRelateToManyForCreateInput>;
  bio?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nonce?: InputMaybe<Scalars['Int']>;
  overrideSignature?: InputMaybe<Scalars['Boolean']>;
  profileImage?: InputMaybe<Scalars['Upload']>;
  projects?: InputMaybe<ProjectRelateToManyForCreateInput>;
  signature?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserOrderByInput = {
  address?: InputMaybe<OrderDirection>;
  bio?: InputMaybe<OrderDirection>;
  id?: InputMaybe<OrderDirection>;
  instagram?: InputMaybe<OrderDirection>;
  isMember?: InputMaybe<OrderDirection>;
  name?: InputMaybe<OrderDirection>;
  nonce?: InputMaybe<OrderDirection>;
  overrideSignature?: InputMaybe<OrderDirection>;
  signature?: InputMaybe<OrderDirection>;
  title?: InputMaybe<OrderDirection>;
  twitter?: InputMaybe<OrderDirection>;
  website?: InputMaybe<OrderDirection>;
};

export type UserRelateToOneForCreateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
};

export type UserRelateToOneForUpdateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  create?: InputMaybe<UserCreateInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type UserUpdateArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  artworks?: InputMaybe<ArtworkRelateToManyForUpdateInput>;
  bio?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  isMember?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  nonce?: InputMaybe<Scalars['Int']>;
  overrideSignature?: InputMaybe<Scalars['Boolean']>;
  profileImage?: InputMaybe<Scalars['Upload']>;
  projects?: InputMaybe<ProjectRelateToManyForUpdateInput>;
  signature?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  address?: InputMaybe<StringFilter>;
  artworks?: InputMaybe<ArtworkManyRelationFilter>;
  bio?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
  instagram?: InputMaybe<StringFilter>;
  isMember?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<StringFilter>;
  nonce?: InputMaybe<IntFilter>;
  overrideSignature?: InputMaybe<BooleanFilter>;
  projects?: InputMaybe<ProjectManyRelationFilter>;
  signature?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  twitter?: InputMaybe<StringFilter>;
  website?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
  address?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};
