import { useRef, useState, useEffect } from 'react';

import { Token } from '@faction-nfts/xsublimatio-smart-contracts';
import { observer } from 'mobx-react-lite';

import { StoredDrug } from 'stores/XSublimatio/XSublimatioStore';
import { useRootStore } from 'providers/RootStoreProvider';
import { MintLoader } from 'pages/Project/components/Content/MintLoader';
import { Icon } from 'modules/components/Icon';

import { CardWrapperStyled, CloseBtn } from './styles';
import { DrugCreatedCard } from '../CreateNftCard/components/DrugCreatedCard';
import { SelectDrugCard } from '../CreateNftCard/components/SelectDrugCard';
import Modal, { ModalHandlers } from '../Modal';

interface ICreateDrugModal {
  drug: StoredDrug;
  onClose?: () => void;
}

export const CreateDrugModal = observer(
  (props: ICreateDrugModal): JSX.Element => {
    const { xsublimatioController } = useRootStore();

    const { drug, onClose } = props;
    const modalRef = useRef<ModalHandlers>(null);

    const [selection, setSelection] = useState<Token[]>([]);
    const [currentPage, setCurrentPage] = useState(0);

    const moleculePage = drug.brewPossibility.recipeMolecules[currentPage];

    const specialWater = drug.brewPossibility.useableSpecialWater;
    const specialWaterUsed = specialWater
      ? selection.includes(specialWater)
      : false;

    useEffect(() => {
      if (!specialWaterUsed && specialWater) {
        moleculePage.push(specialWater);
      }
    }, [specialWater, specialWaterUsed, moleculePage]);

    const prevPage = (pageId: number) => {
      selection.splice(pageId - 1, 1);

      setSelection(selection);
      setCurrentPage(currentPage - 1);
    };

    const nextPage = async (selectedToken?: Token): Promise<void> => {
      try {
        let success = true;
        const startBrew = currentPage + 1 === drug.recipe.length;

        const newSelection = selectedToken
          ? [...selection, selectedToken]
          : selection;

        if (selectedToken) {
          setSelection(newSelection);
        }

        if (startBrew) {
          const id = await xsublimatioController.brewDrug(drug, newSelection);
          success = !!id;
        }

        if (success) {
          setCurrentPage(currentPage + 1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    return (
      <>
        <Modal ref={modalRef} onClose={onClose} defaultOpened={true}>
          <CloseBtn onClick={onClose}>
            <Icon name={'cross'} />
          </CloseBtn>
          <CardWrapperStyled>
            {!xsublimatioController.isDrugCreated && (
              <SelectDrugCard
                key={currentPage}
                moleculeCount={drug.recipe.length}
                pageId={currentPage}
                prevPage={prevPage}
                nextPage={nextPage}
                drug={drug.name}
                moleculeName={drug.recipe[currentPage].name}
                molecules={moleculePage}
              />
            )}
            {xsublimatioController.isDrugCreated && <DrugCreatedCard />}
          </CardWrapperStyled>
        </Modal>
        {xsublimatioController.isBrewLoading && <MintLoader />}
      </>
    );
  }
);
