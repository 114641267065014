import { observer } from 'mobx-react-lite';

import { shortenAddress } from 'utils/web3';
import { SecondaryButton } from 'modules/components/Button';
import { useRootStore } from 'providers/RootStoreProvider';

interface StatusProps {
  onClick: () => void;
}

const Status = observer((props: StatusProps): JSX.Element => {
  const { onClick } = props;
  const { web3Store } = useRootStore();

  return (
    <SecondaryButton
      size={'xs'}
      border={'pill'}
      textTransform={'capitalize'}
      onClick={onClick}
    >
      {web3Store.address ? shortenAddress(web3Store.address) : 'Connect'}
    </SecondaryButton>
  );
});

export default Status;
