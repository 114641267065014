import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import CircleButton, { CircleButtonProps } from './CircleButton';

const OutlinedCircleButtonStyled = styled(CircleButton)`
  position: relative;
  background: transparent;
  ${({ theme }) => css`
    border-color: ${theme.colors.primary[4]};
    color: ${theme.colors.primary[4]};

    &:disabled {
      color: ${theme.colors.primary[12]};
      border-color: ${theme.colors.primary[12]};
    }

    &:active,
    &:hover {
      border-color: ${theme.colors.primary[22]};
      background: ${theme.colors.primary[22]};
      color: ${theme.colors.primary[9]};
    }
  `}
`;

const OutlinedCircleButton: FC<CircleButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <OutlinedCircleButtonStyled {...rest}>
      {children}
    </OutlinedCircleButtonStyled>
  );
};

export default OutlinedCircleButton;
