import { gql } from '@apollo/client';

export const GET_FEATURED_PROJECTS = gql`
  query GetFeaturedProjects {
    projects {
      name
      artistName
      address
      type
      launchDate
      artistName
      mintType
      mintLimit
      artworksCount
      homeFeaturedArtwork {
        publicUrl
      }
      artworks {
        name
        imageUrl
        tokenId
        placeholderImageUrl
        thumbnailUrl
      }
      exampleArtworks {
        name
        image {
          publicUrl
        }
      }
      artist {
        address
      }
      homepageWeight
    }
  }
`;

export const GET_HOME_PROJECTS = gql`
  query GetHomeProjects {
    projects(where: { featuredOnHomepage: { equals: true } }) {
      name
      artistName
      type
      homepageWeight
      homeFeaturedArtwork {
        publicUrl
      }
    }
  }
`;

export const GET_PROJECT = gql`
  query GetProject($projectType: ProjectTypeType!) {
    project(where: { type: $projectType }) {
      type
      launchDate
      artistName
      artist {
        address
        name
        profileImage {
          publicUrl
        }
      }
      artworks {
        id
        tokenId
        imageUrl
        name
        owner {
          address
        }
        thumbnailUrl
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser($address: String!) {
    user(where: { address: $address }) {
      name
      bio
      address
      isMember
      profileImage {
        filename
        publicUrl
      }
      twitter
      instagram
      website
      signature
    }
  }
`;

export const GET_USER_NONCE = gql`
  query GetUserNonce($address: String!) {
    user(where: { address: $address }) {
      nonce
    }
  }
`;

export const GET_ARTWORK = gql`
  query GetArtwork($projectType: ProjectTypeType!, $tokenId: String!) {
    project(where: { type: $projectType }) {
      type
      launchDate
      artistName
      artist {
        address
        name
        profileImage {
          publicUrl
        }
      }
      artworks(where: { tokenId: { contains: $tokenId } }) {
        createdAt
        id
        tokenId
        name
        description
        imageUrl
        animationUrl
        owner {
          address
          name
          profileImage {
            publicUrl
          }
        }
      }
    }
  }
`;

export const GET_USER_PROJECTS = gql`
  query GetUserProjects($walletAddress: String!) {
    user(where: { address: $walletAddress }) {
      projects {
        name
        artistName
        address
        type
        launchDate
        artistName
        mintType
        mintLimit
        artworksCount
        artworks {
          name
          imageUrl
          tokenId
          placeholderImageUrl
          thumbnailUrl
        }
        exampleArtworks {
          name
          image {
            publicUrl
          }
        }
      }
    }
  }
`;

export const GET_USER_COLLECTED = gql`
  query GetUserCollected($walletAddress: String!) {
    user(where: { address: $walletAddress }) {
      artworks {
        name
        imageUrl
        tokenId
        thumbnailUrl
        placeholderImageUrl
        project {
          artistName
          artist {
            name
          }
          type
        }
      }
    }
  }
`;

export const GET_DAO_MEMBERS = gql`
  query GetDaoMembers($isMember: Boolean!) {
    users(where: { isMember: { equals: $isMember } }) {
      id
      name
      title
      profileImage {
        publicUrl
      }
    }
  }
`;
