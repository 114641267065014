import { SupportedChainId } from 'constants/chains';

export enum Projects {
  XSUBLIMATIO = 'xsublimatio',
  STENCILS = 'stencils',
}

const projectContracts: {
  [key in Projects]: {
    [chainKey in SupportedChainId]?: { address: string };
  };
} = {
  [Projects.XSUBLIMATIO]: {
    [SupportedChainId.MAINNET]: {
      address: '0xb80Bd234e8630e01Ad21EfF94b8c2C4Fd76d5DBc',
    },
    [SupportedChainId.GOERLI]: {
      address: '0x0a1D2A9190380759F6ded6A916748Adde983746c',
    },
    [SupportedChainId.GANACHE]: {
      address: process.env.REACT_APP_XSUBLIMATIO_ADDRESS as string,
    },
  },
  [Projects.STENCILS]: {
    [SupportedChainId.MAINNET]: {
      address: '0x35dA87Ea06436d8010AC0FFc666C37257A658333',
    },
    [SupportedChainId.GOERLI]: {
      address: '0x0d2501e6691D5166108482320398754aa40DA23C',
    },
  },
};

export default projectContracts;
