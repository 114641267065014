import { FC } from 'react';

import styled from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const BorderlessButtonStyled = styled(Button)`
  position: relative;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary[4]};

  &:disabled {
    color: ${({ theme }) => theme.colors.primary[16]};
  }

  &:active,
  &:hover {
    color: ${({ theme }) => theme.colors.primary[1]};
  }
`;

const BorderlessButton: FC<ButtonProps> = (props) => {
  const { children, ...rest } = props;
  return <BorderlessButtonStyled {...rest}>{children}</BorderlessButtonStyled>;
};

export default BorderlessButton;
