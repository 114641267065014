import { XSublimatioStore, XSublimatioController } from 'stores/XSublimatio';

import FeaturedProjectsStore from './FeaturedProjectsStore';
import Web3Store from './web3/Web3Store';
import { StencilsStore } from './Stencils/StencilsStore';
import { StencilsController } from './Stencils/StencilsController';

export class RootStore {
  xsublimatioStore: XSublimatioStore;

  xsublimatioController: XSublimatioController;

  web3Store: Web3Store;

  featuredProjectsStore: FeaturedProjectsStore;

  stencilsStore: StencilsStore;

  stencilsController: StencilsController;

  constructor() {
    this.web3Store = new Web3Store();
    this.xsublimatioStore = new XSublimatioStore(this);
    this.xsublimatioController = new XSublimatioController(this);
    this.stencilsStore = new StencilsStore(this);
    this.stencilsController = new StencilsController(this);
    this.featuredProjectsStore = new FeaturedProjectsStore();
  }
}
