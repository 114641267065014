import { useParams } from 'react-router-dom';

const ArtworkVideoPage = (): JSX.Element => {
  const { tokenId } = useParams<{ tokenId: string }>();
  return (
    <>
      <video
        autoPlay
        loop
        muted
        controls={false}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <source
          src={`https://xsublimation.s3.amazonaws.com/image/original/${tokenId}.webp`}
          type="video/webp"
        />
      </video>
    </>
  );
};

export default ArtworkVideoPage;
