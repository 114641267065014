import { gql } from '@apollo/client';

export const CREATE_USER = gql`
  mutation CreateUser(
    $nonce: Int
    $address: String
    $name: String
    $title: String
    $bio: String
    $twitter: String
    $instagram: String
    $website: String
    $profileImage: Upload
    $signature: String!
  ) {
    createUser(
      data: {
        nonce: $nonce
        address: $address
        name: $name
        title: $title
        bio: $bio
        twitter: $twitter
        instagram: $instagram
        website: $website
        profileImage: $profileImage
        signature: $signature
      }
    ) {
      name
      bio
      address
      isMember
      profileImage {
        filename
        publicUrl
      }
      twitter
      instagram
      website
      signature
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $nonce: Int
    $address: String
    $name: String
    $title: String
    $bio: String
    $twitter: String
    $instagram: String
    $website: String
    $profileImage: Upload
    $signature: String!
  ) {
    updateUser(
      where: { address: $address }
      data: {
        nonce: $nonce
        address: $address
        name: $name
        title: $title
        bio: $bio
        twitter: $twitter
        instagram: $instagram
        website: $website
        profileImage: $profileImage
        signature: $signature
      }
    ) {
      name
      bio
      address
      isMember
      profileImage {
        filename
        publicUrl
      }
      twitter
      instagram
      website
      signature
    }
  }
`;
