import { ButtonHTMLAttributes, FC } from 'react';

import styled from 'styled-components/macro';

const BaseButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: inherit;
  overflow: hidden;
  transition: all 0.25s ease;

  &::-moz-focus-inner {
    border-style: none;
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
  }

  @media print {
    color-adjust: exact;
  }
`;

export interface BaseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  onClick?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
}

const BaseButton: FC<BaseButtonProps> = (props) => {
  const { className, onClick, onFocus, children, disabled, ...rest } = props;

  return (
    <BaseButtonStyled
      className={className}
      onClick={onClick}
      onFocus={onFocus}
      disabled={disabled}
      {...rest}
    >
      {children}
    </BaseButtonStyled>
  );
};

export default BaseButton;
