import * as React from 'react';

import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

import { Icon } from 'modules/components/Icon';
import { LINKS, PATHS } from 'router/config';
import { useRootStore } from 'providers/RootStoreProvider';
import { getUser } from 'api/sdk';

const UserMenuStyled = styled.div`
  position: relative;
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 54px;
  right: -10px;
  width: 200px;
  background-color: ${(props) => props.theme.colors.primary[9]};
  padding: 30px;
  box-shadow: 0px 4px 9px rgba(19, 71, 255, 0.1);
`;

const IconContainer = styled.div`
  svg {
    height: 20px;
    width: 20px;
  }
`;

const TextStyled = styled.span`
  font-weight: 500;
`;

const UserButtonStyled = styled.button`
  position: relative;
  padding: 0;
  background-color: transparent;
  border: none;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.25s ease;
  &:hover {
    color: rgba(0, 0, 0, 1);
  }
  img {
    width: 24px;
    height: 24px;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const MenuItemWrapper = styled.div`
  position: relative;
  padding: 0;
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 19px;
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.primary[9]};
  border: none;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.25s ease;
  margin-bottom: 25px;
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    color: rgba(19, 71, 255, 1);
  }
  &:hover {
    color: rgba(19, 71, 255, 1);
  }
`;

interface IMenuItem {
  IconComponent: React.FC;
  name: string;
  route: string;
}

const menuItems = (walletAddress?: string): IMenuItem[] => [
  {
    IconComponent: ({ ...props }) => <Icon name={'user-filled'} {...props} />,
    name: 'Collected',
    route: LINKS.USER(walletAddress).COLLECTED,
  },
  // {
  //   IconComponent: ({ ...props }) => <Icon name={'palette'} {...props} />,
  //   name: 'Projects',
  //   route: LINKS.USER(walletAddress).PROJECTS,
  // },
  // {
  //   IconComponent: ({ ...props }) => <Icon name={'heart'} {...props} />,
  //   name: 'Favorites',
  //   route: LINKS.USER.FAVORITES,
  // },
  // {
  //   IconComponent: ({ ...props }) => <Icon name={'dark-mode'} {...props} />,
  //   name: 'Dark mode',
  // },
  {
    IconComponent: ({ ...props }) => <Icon name={'gear'} {...props} />,
    name: 'Settings',
    route: LINKS.USER(walletAddress).SETTINGS,
  },
];

export const UserMenu = observer(() => {
  const [isOpen, setOpen] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const btnRef = React.useRef<HTMLButtonElement | null>(null);
  const { web3Store } = useRootStore();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: userData } = useQuery(
    ['user-info', web3Store.address],
    async () => {
      if (!web3Store.address) {
        return null;
      }

      if (!ethers.utils.isAddress(web3Store.address)) return null;

      const res = await getUser(web3Store.address);
      return res;
    },
    {
      onError: () => {
        toast.error('Something went wrong', {
          position: 'top-right',
        });
      },
    }
  );

  const clickOutside = (event: MouseEvent) => {
    if (!btnRef.current?.contains(event.target as Node)) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => document.removeEventListener('mousedown', clickOutside);
  }, []);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    web3Store.disconnect();
    handleClose();

    if (
      location.pathname ===
        `/${PATHS.USER.INDEX}/${PATHS.USER.SETTINGS.replace(
          ':walletAddress',
          web3Store.address as string
        )}` ||
      location.pathname ===
        `/${PATHS.USER.INDEX}/${PATHS.USER.COLLECTED.replace(
          ':walletAddress',
          web3Store.address as string
        )}`
    ) {
      navigate(PATHS.HOME);
    }
  };

  const renderList = () => {
    return menuItems(web3Store.address).map(
      ({ IconComponent, name, route }) => {
        const wrapProps: { [key: string]: any } = {};
        if (route) {
          // link props
          wrapProps.to = route;
          wrapProps.as = NavLink;
        } else {
          // button props
          wrapProps.as = 'button';
        }
        return (
          <MenuItemWrapper {...wrapProps} key={name} onClick={handleClose}>
            <TextStyled>{name}</TextStyled>
            <IconContainer>
              <IconComponent />
            </IconContainer>
          </MenuItemWrapper>
        );
      }
    );
  };
  return (
    <UserMenuStyled>
      <UserButtonStyled onClick={handleToggle} ref={btnRef}>
        {!userData?.profileImage?.publicUrl ? (
          <Icon name={'user-filled-circle'} />
        ) : (
          <img src={userData?.profileImage?.publicUrl || ''} alt="user" />
        )}
      </UserButtonStyled>
      {isOpen && (
        <DropdownWrapper ref={dropdownRef}>
          {renderList()}
          {web3Store.isActive && (
            <MenuItemWrapper as={'button'} onClick={handleLogout}>
              <TextStyled>{'Log out'}</TextStyled>
              <IconContainer>
                <Icon name={'logout'} />
              </IconContainer>
            </MenuItemWrapper>
          )}
        </DropdownWrapper>
      )}
    </UserMenuStyled>
  );
});
