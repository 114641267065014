import { useEffect } from 'react';

import styled from 'styled-components/macro';
import { AnimatePresence, motion } from 'framer-motion';
import { query } from 'atomic-layout';

const ModalStyled = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999999;
`;

const BackdropStyled = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
`;

const BodyStyled = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  height: 280px;

  @media ${query({ from: 'sm' })} {
    width: 344px;
    height: 344px;
  }
  @media ${query({ from: 'md' })} {
    width: 556px;
    height: 556px;
  }
`;

export const MintLoader = (): JSX.Element => {
  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');
    return () => {
      document.body.removeAttribute('style');
    };
  });
  return (
    <AnimatePresence>
      <ModalStyled>
        <BackdropStyled />
        <BodyStyled
          transition={{
            delay: 0.5,
            duration: 1,
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <video
            width="100%"
            height="100%"
            autoPlay
            controls={false}
            loop={true}
            muted={true}
          >
            <source
              src="/video/Loader_Animation_Minting.mp4"
              type="video/mp4"
            />
          </video>
        </BodyStyled>
      </ModalStyled>
    </AnimatePresence>
  );
};
