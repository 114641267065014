export enum FilterTypes {
  ALL = 'all',
  UPCOMING = 'upcoming',
  LIVE = 'live',
}

export interface BaseArtwork {
  image: string;
  name: string;
}

export interface LiveArtwork extends BaseArtwork {
  image: string;
  link: string;
  placeholder: string;
}

export interface BaseProject {
  name: FilterTypes;
  status: string;
  isLive: boolean;
  hasEnded: boolean;
  artistAddress?: string;
  artistName: string;
  mintType: string;
  mintCount: number;
  mintLimit: number;
}

export interface UpcomingProject extends BaseProject {
  type: FilterTypes.UPCOMING;
  exampleArtwork: BaseArtwork | undefined;
  isLive: true;
}

export interface LiveProject extends BaseProject {
  type: FilterTypes.LIVE;
  artworks: LiveArtwork[];
  link: string;
  isLive: true;
}
