import { css } from 'styled-components/macro';

import LightWoff from 'assets/fonts/FSKoopman-Light.woff';
import LightWoff2 from 'assets/fonts/FSKoopman-Light.woff2';
import LightItalicWoff from 'assets/fonts/FSKoopman-LightItalic.woff';
import LightItalicWoff2 from 'assets/fonts/FSKoopman-LightItalic.woff2';
import ItalicWoff from 'assets/fonts/FSKoopman-Italic.woff';
import ItalicWoff2 from 'assets/fonts/FSKoopman-Italic.woff2';
import ExtraBoldWoff from 'assets/fonts/FSKoopman-ExtraBold.woff';
import ExtraBoldWoff2 from 'assets/fonts/FSKoopman-ExtraBold.woff2';
import ExtraBoldItalicWoff from 'assets/fonts/FSKoopman-ExtraBoldItalic.woff';
import ExtraBoldItalicWoff2 from 'assets/fonts/FSKoopman-ExtraBoldItalic.woff2';
import BoldWoff from 'assets/fonts/FSKoopman-Bold.woff';
import BoldWoff2 from 'assets/fonts/FSKoopman-Bold.woff2';
import BoldItalicWoff from 'assets/fonts/FSKoopman-BoldItalic.woff';
import BoldItalicWoff2 from 'assets/fonts/FSKoopman-BoldItalic.woff2';
import MediumWoff from 'assets/fonts/FSKoopman-Medium.woff';
import MediumWoff2 from 'assets/fonts/FSKoopman-Medium.woff2';
import MediumItalicWoff from 'assets/fonts/FSKoopman-MediumItalic.woff';
import MediumItalicWoff2 from 'assets/fonts/FSKoopman-MediumItalic.woff2';
import RegularWoff from 'assets/fonts/FSKoopman-Regular.woff';
import RegularWoff2 from 'assets/fonts/FSKoopman-Regular.woff2';
import ItalicVF from 'assets/fonts/FSKoopmanItalicVF.woff2';
import RegularVF from 'assets/fonts/FSKoopmanVF.woff2';

export const Fonts = css`
  @font-face {
    font-family: 'Koopman VF';
    src: url(${RegularVF}) format('woff2-variations'),
      url(${RegularVF}) format('woff2');
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman VF';
    src: url(${ItalicVF}) format('woff2-vatiations'),
      url(${ItalicVF}) format('woff2');
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${LightWoff2}) format('woff2'), url(${LightWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${ItalicWoff2}) format('woff2'), url(${ItalicWoff}) format('woff');
    font-weight: 400;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${BoldWoff2}) format('woff2'), url(${BoldWoff}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${BoldItalicWoff2}) format('woff2'),
      url(${BoldItalicWoff}) format('woff');
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${ExtraBoldWoff2}) format('woff2'),
      url(${ExtraBoldWoff}) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${ExtraBoldItalicWoff2}) format('woff2'),
      url(${ExtraBoldItalicWoff}) format('woff');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${MediumWoff2}) format('woff2'), url(${MediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${MediumItalicWoff2}) format('woff2'),
      url(${MediumItalicWoff}) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${RegularWoff2}) format('woff2'),
      url(${RegularWoff}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Koopman';
    src: url(${LightItalicWoff2}) format('woff2'),
      url(${LightItalicWoff}) format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  }
`;
