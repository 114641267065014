import { BigNumber, ethers } from 'ethers';
import { getTokenFromId, Token } from '@faction-nfts/stencils-smart-contracts';
import {
  Stencils,
  Stencils__factory,
} from '@faction-nfts/stencils-smart-contracts/dist/esm/ethers';
import { Contract, ContractTransaction } from '@ethersproject/contracts';
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers';
import { toast } from 'react-toastify';
import { makeAutoObservable } from 'mobx';

import type { RootStore } from 'stores/RootStore';
import projectContracts, { Projects } from 'contracts/projects';
import { DEFAULT_CHAIN_ID } from 'constants/chains';
import { INFURA_NETWORK_URLS } from 'constants/chainInfo';

const defaultNode = INFURA_NETWORK_URLS[DEFAULT_CHAIN_ID];
const bucketUrl = process.env.REACT_APP_XSUBLIMATIO_BUCKET as string;
const contractAddress = projectContracts[Projects.STENCILS][DEFAULT_CHAIN_ID]
  ?.address as string;
const readJsonRpcProvider = new JsonRpcProvider(defaultNode);

export interface IList {
  name: string;
  totalSupply: number;
  maxSupply: number;
}

export class StencilsController {
  private rootStore: RootStore;

  public isBrewLoading: boolean;

  public isDrugCreated: boolean;

  public tokenId?: string;

  public contract: {
    read: Stencils;
    write?: (signer: JsonRpcSigner) => Stencils;
  } | null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.isBrewLoading = false;
    this.isDrugCreated = false;
    this.tokenId = undefined;
    this.contract = contractAddress
      ? {
          read: new Contract(
            contractAddress,
            Stencils__factory.abi,
            readJsonRpcProvider
          ) as Stencils,
          write: (signer: JsonRpcSigner) =>
            new Contract(
              contractAddress,
              Stencils__factory.abi,
              signer
            ) as Stencils,
        }
      : null;

    makeAutoObservable(this);
  }

  public setBrewLoading = (status: boolean): void => {
    this.isBrewLoading = status;
  };

  public setIsDrugCreated = (status: boolean): void => {
    this.isDrugCreated = status;
  };

  public setTokenId = (tokenId?: string): void => {
    this.tokenId = tokenId;
  };

  public isProjectLive = async () => {
    if (!this.contract) return { isLive: false, launchTimestamp: 1666922400 };

    const launchTimestampBN = await this.contract?.read.LAUNCH_TIMESTAMP();
    const launchTimestamp = launchTimestampBN.toNumber();
    const currentTimestamp = Math.floor(Date.now() / 1000);

    return { isLive: currentTimestamp > launchTimestamp, launchTimestamp };
  };

  // TODO: get sync stuff
  public getPrice = async (): Promise<string> => {
    if (!this.contract) return '0';

    try {
      const price = await this.contract.read.pricePerTokenMint();
      return ethers.utils.formatUnits(price, 'ether');
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public claim = async (amount: number): Promise<ContractTransaction> => {
    if (!this.contract?.write) throw new Error('Contract not initialized');
    const buyer = this.rootStore.web3Store.address as string;
    const signer = this.rootStore.web3Store.provider?.getSigner();

    if (!signer) throw new Error('Signer not initialized');
    if (this.rootStore.web3Store.chainId !== '0x1') {
      toast.error('Please switch to Ethereum Mainnet', {
        position: 'top-right',
      });
      throw new Error('Wrong network');
    }

    const contract = this.contract.write(signer);

    const purchaseInformation = await this.getPurchaseInformationFor();
    if (purchaseInformation === null) throw new Error('Impossible');
    const tx = await contract.claim(buyer, amount, 1, {
      value: purchaseInformation.price_.mul(amount),
    });

    return tx;
  };

  public getPurchaseInformationFor = async () => {
    const buyer =
      this.rootStore.web3Store.address ||
      '0x0000000000000000000000000000000000000000';
    // if (!buyer) return null;
    if (!this.contract) return null;

    try {
      return await this.contract.read.getPurchaseInformationFor(buyer);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public purchasePhysicalCopy = async (
    tokenId: string
  ): Promise<ContractTransaction> => {
    if (!this.contract) throw new Error('Contract not defined');
    try {
      const account = this.rootStore.web3Store.address;
      const signer = this.rootStore.web3Store.provider?.getSigner();
      if (!this.contract.write || !account || !signer) {
        throw new Error('Wallet not connected');
      }
      if (this.rootStore.web3Store.chainId !== '0x1') {
        toast.error('Please switch to Ethereum Mainnet', {
          position: 'top-right',
        });
        throw new Error('Wrong network');
      }

      const physicalPrice = await this.contract.read.physicalPrice();

      const gasEstimate = await this.contract
        .write(signer)
        .estimateGas.purchasePhysical(BigNumber.from(tokenId), {
          value: physicalPrice,
        });

      return await this.contract
        .write(signer)
        .purchasePhysical(BigNumber.from(tokenId), {
          value: physicalPrice,
          gasLimit: gasEstimate.mul(2),
        });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  public purchase = async (amount = 1) => {
    if (!this.contract) return;
    const account = this.rootStore.web3Store.address;
    if (!account) return;
    const signer = this.rootStore.web3Store.provider?.getSigner();
    if (!this.contract.write || !account || !signer) {
      throw new Error('Wallet not connected');
    }
    if (this.rootStore.web3Store.chainId !== '0x1') {
      toast.error('Please switch to Ethereum Mainnet', {
        position: 'top-right',
      });
      throw new Error('Wrong network');
    }

    try {
      const data = await this.contract.read.getPurchaseInformationFor(account);
      const price = data.price_;

      const gasEstimate = await this.contract
        .write(signer)
        .estimateGas.purchase(account, amount, 1, {
          value: price.mul(amount),
        });
      const res = await this.contract
        .write(signer)
        .purchase(account, amount, 1, {
          value: price.mul(amount),
          gasLimit: gasEstimate.mul(2),
        });

      toast.success('Success Purchase', {
        position: 'top-right',
      });
      return res;
    } catch (error) {
      console.error(error);
      toast.error('Error Purchase', {
        position: 'top-right',
      });
      throw error;
    }
  };
}
