import { getAddress } from '@ethersproject/address';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import {
  TypedDataDomain,
  TypedDataField,
} from '@ethersproject/abstract-signer';
import currency from 'currency.js';
// returns the checksummed address if the address is valid, otherwise returns false
export const isAddress = (value: any): string | false => {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
};

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export const shortenAddress = (address: string, chars = 4): string => {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
};

// account is not optional
export const getSigner = (
  library: Web3Provider,
  account: string
): JsonRpcSigner => {
  return library.getSigner(account).connectUnchecked();
};

export const formatCurrency = (value: number): string => {
  return currency(value, {
    symbol: '$',
    separator: ',',
    decimal: '.',
    precision: 0,
  }).format();
};

export const getSignatureTypes = (
  data: Record<string, string | number>,
  keyName = ''
): Record<string, TypedDataField[]> => {
  const types: TypedDataField[] = Object.keys(data).map((name) => ({
    name,
    type: typeof data[name] === 'string' ? 'string' : 'uint',
  }));

  return {
    [keyName]: types,
  };
};

export const getSignature = async (
  types: Record<string, TypedDataField[]>,
  data: Record<string, number | string>,
  provider: JsonRpcSigner,
  domain: TypedDataDomain = {}
): Promise<string> => {
  try {
    return await provider._signTypedData(domain, types, data);
  } catch (e) {
    console.error(e);
    throw new Error('Failed requesting signature');
  }
};
