import { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';

interface CountDownTimerProps {
  seconds: number;
  onFinished?: () => void;
  refetch?: () => void;
  refetchIn?: number;
}

export const CountDownTimer = (props: CountDownTimerProps): JSX.Element => {
  const { seconds, onFinished, refetch, refetchIn } = props;
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(seconds);
  const estimateRef = useRef<number>();

  // set new timeLeft if we get more estimation than our timeLeft
  useEffect(() => {
    if (!estimateRef.current) return;

    if (seconds > estimateRef.current) {
      setTimeLeft(seconds);
    }
  }, [seconds]);

  useEffect(() => {
    if (refetch && timeLeft === refetchIn) {
      if (!estimateRef.current) estimateRef.current = seconds;

      refetch();
    }
    // exit early when we reach 0

    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);

      if (timeLeft === 0 && onFinished) {
        onFinished();
      }
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  return <>{dayjs.unix(timeLeft).format('mm:ss')}</>;
};
