import { query } from 'atomic-layout';
import styled from 'styled-components/macro';

import { Icon } from 'modules/components/Icon';

const MenuItem = styled.a`
  position: relative;
  cursor: pointer;
  color: rgba(128, 128, 128, 1);
  transition: color 0.25s ease;
  &:hover {
    color: ${(props) => props.theme.colors.primary[9]};
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Social = (): JSX.Element => (
  <>
    {/*<MenuItem>*/}
    {/*  <Icon name={'telegram'} />*/}
    {/*</MenuItem>*/}
    <MenuItem href={'https://www.instagram.com/faction_nft/'} target={'_blank'}>
      <Icon name={'instagram'} />
    </MenuItem>
    {/*<MenuItem>*/}
    {/*  <Icon name={'medium'} />*/}
    {/*</MenuItem>*/}
    <MenuItem href={'https://twitter.com/faction_art'} target={'_blank'}>
      <Icon name={'twitter'} />
    </MenuItem>
    <MenuItem href={'https://discord.gg/MpvH5U5Xhz'} target={'_blank'}>
      <Icon name={'discord'} />
    </MenuItem>
  </>
);
