import { query } from 'atomic-layout';
import styled from 'styled-components/macro';

export const WrapperStyled = styled.div`
  position: relative;
`;

export const TitleSection = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 20px;
  margin-bottom: 50px;
`;

export const Title = styled.div`
  font-size: 45px;
`;

export const AssetCount = styled.div`
  color: ${(props) => props.theme.colors.primary[15]};
  font-size: 20px;
  flex-grow: 1;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0 20px;
  @media ${query({ from: 'lg' })} {
    margin-bottom: 100px;
    padding: 0 150px;
  }
`;

export const ListsVisualContainerStyled = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
`;

export const ListsTextContainerStyled = styled(ListsVisualContainerStyled)`
  grid-template-columns: 1fr;
  grid-gap: 100px;

  @media ${query({ from: 'md' })} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const GridWrapperStyled = styled.div`
  padding: 0 20px;
  //div {
  //  justify-content: start !important;
  //}
  @media ${query({ from: 'lg' })} {
    padding: 0 110px;
  }
`;
