import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import CircleButton, { CircleButtonProps } from './CircleButton';

const PrimaryCircleButtonStyled = styled(CircleButton)`
  position: relative;
  ${({ theme }) => css`
    background: ${theme.colors.primary[6]};
    color: ${theme.colors.primary[1]};
    border-color: ${theme.colors.primary[1]};

    &:disabled {
      background: ${theme.colors.primary[6]};
      border-color: ${theme.colors.primary[21]};
      color: ${theme.colors.primary[21]};
    }

    &:active,
    &:hover {
      background: ${theme.colors.primary[1]};
      color: ${theme.colors.primary[9]};
    }
  `}
`;

const PrimaryCircleButton: FC<CircleButtonProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <PrimaryCircleButtonStyled {...rest}>{children}</PrimaryCircleButtonStyled>
  );
};

export default PrimaryCircleButton;
