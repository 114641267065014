import styled from 'styled-components/macro';
import { Composition } from 'atomic-layout';

import { Navigation } from './Navigation';
import { Footnote } from './Footnote';

const FooterStyled = styled.div`
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary[22]};
  color: ${(props) => props.theme.colors.primary[9]};
`;

const areas = `
  navigation
  footnote
`;

const Footer = (): JSX.Element => (
  <Composition
    areas={areas}
    as={FooterStyled}
    paddingVertical={'50px'}
    paddingHorizontal={'20px'}
    paddingHorizontalLg={'100px'}
    alignItems={'center'}
    alignItemsMd={'start'}
  >
    {(Areas) => {
      return (
        <>
          <Areas.Navigation
            marginBottom={'150px'}
            marginBottomLg={'240px'}
            flexDirection={'column'}
            flexDirectionMd={'row'}
            gap={'30px'}
            gapLg={'50px'}
            flex
          >
            <Navigation />
          </Areas.Navigation>
          <Areas.Footnote>
            <Footnote />
          </Areas.Footnote>
        </>
      );
    }}
  </Composition>
);

export default Footer;
