import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { useQuery } from 'react-query';

import { CountDownTimer } from '../CountDownTimer';

interface CountdownInterface {
  estimateEndpoint: string;
  tokenId: string;
  onFinished: () => void;
}

enum Status {
  LOADING = 'loading',
  IN_PROGRESS = 'in_progress',
  OVERTIME = 'overtime',
  DONE = 'done',
}

export const Countdown = ({
  estimateEndpoint,
  tokenId,
  onFinished,
}: CountdownInterface) => {
  const [intent, setIntent] = useState(0);
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const {
    data: countdownResponse,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['fetch-estimate', tokenId, intent], async () => {
    const res = await axios.get(estimateEndpoint);
    return res.data.estimate;
  });

  const reloadIn = (ms: number) => {
    const timeout = setTimeout(() => {
      setIntent(intent + 1);
      refetch();
    }, ms);

    return () => clearTimeout(timeout);
  };

  useEffect(() => {
    if (isLoading) return setStatus(Status.LOADING);

    if (countdownResponse === 0) {
      onFinished();

      return setStatus(Status.DONE);
    }

    if (countdownResponse < 0) {
      reloadIn(10 * 1000);

      return setStatus(Status.LOADING);
    }

    if (countdownResponse <= 10) {
      setStatus(Status.LOADING);

      return reloadIn(10 * 1000);
    }

    setStatus(Status.IN_PROGRESS);
  }, [countdownResponse, isLoading, isRefetching]);

  const seconds = Math.round(countdownResponse);

  const onCounterFinished = () => {
    reloadIn(0);
  };

  return (
    <>
      {status === Status.IN_PROGRESS ? (
        <CountDownTimer
          seconds={seconds}
          onFinished={onCounterFinished}
          refetch={() => reloadIn(0)}
          refetchIn={10}
        />
      ) : status === Status.LOADING || Status.OVERTIME ? (
        'Loading...'
      ) : (
        status === Status.DONE && 'View'
      )}
    </>
  );
};
