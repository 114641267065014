import { Project } from 'api/generated/graphql';

import { default as getIsLive } from './isLive';
import hasEnded from './hasEnded';
import formatLaunchDate from './formatLaunchDate';

const getStatus = (project: Project) => {
  const isLive = getIsLive(project);
  const ended = hasEnded(project);
  const launchDate = formatLaunchDate(project) || '';

  if (isLive && !ended) {
    return 'Live';
  } else if (ended) {
    return 'Ended';
  } else if (launchDate) {
    return `Begins ${launchDate}`;
  } else {
    return '';
  }
};

export default getStatus;
