import * as React from 'react';

import styled from 'styled-components/macro';

import { Icon } from 'modules/components/Icon';

const SocialListStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const NavItemWrapper = styled.div`
  position: relative;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

const SocialLink = styled.a`
  cursor: pointer;
  color: rgba(88, 124, 254, 0.5);
  margin-right: 20px;
  transition: color 0.25s ease;
  &:hover {
    color: rgba(88, 124, 254, 1);
  }
  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SocialList = (): JSX.Element => {
  return (
    <SocialListStyled>
      {/*<NavItemWrapper>*/}
      {/*  <SocialLink>*/}
      {/*    <Icon name={'telegram'} />*/}
      {/*  </SocialLink>*/}
      {/*</NavItemWrapper>*/}
      <NavItemWrapper>
        <SocialLink
          href={'https://www.instagram.com/faction_nft/'}
          target={'_blank'}
        >
          <Icon name={'instagram'} />
        </SocialLink>
      </NavItemWrapper>
      {/*<NavItemWrapper>*/}
      {/*  <SocialLink target={'_blank'}>*/}
      {/*    <Icon name={'medium'} />*/}
      {/*  </SocialLink>*/}
      {/*</NavItemWrapper>*/}
      <NavItemWrapper>
        <SocialLink href={'https://twitter.com/faction_art'} target={'_blank'}>
          <Icon name={'twitter'} />
        </SocialLink>
      </NavItemWrapper>
      <NavItemWrapper>
        <SocialLink href={'https://discord.gg/MpvH5U5Xhz'} target={'_blank'}>
          <Icon name={'discord'} />
        </SocialLink>
      </NavItemWrapper>
    </SocialListStyled>
  );
};
