import styled from 'styled-components/macro';

import { Icon } from 'modules/components/Icon';

const TermsStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 19px;
  font-weight: 400;
  max-width: 560px;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  svg {
    width: 35px;
    height: 35px;
  }
`;

export const Terms = () => (
  <TermsStyled>
    <IconWrapper>
      <Icon name={'logo-cross'} />
    </IconWrapper>
    © 2022 Faction
  </TermsStyled>
);
