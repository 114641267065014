import styled from 'styled-components/macro';
import { Composition } from 'atomic-layout';

import { Terms } from 'modules/app/Footer/Terms';
import { Social } from 'modules/app/Footer/Social';

const FootnoteStyled = styled.div`
  position: relative;
  color: ${(props) => props.theme.colors.primary[9]};
  font-size: 12px;
`;

const PolicyStyled = styled.div`
  display: flex;
`;

const LinksStyled = styled.div`
  display: flex;
  width: 100%;
`;

const areasDesktop = `
  policy links
`;

const areasMobile = `
  links 
  policy 
`;

export const Footnote = (): JSX.Element => {
  return (
    <Composition
      as={FootnoteStyled}
      areas={areasMobile}
      areasLg={areasDesktop}
      gridTemplateColumns={'1fr'}
      gridTemplateColumnsLg={'1fr 1fr'}
    >
      {(Areas) => {
        return (
          <>
            <Areas.Policy as={PolicyStyled}>
              <Terms />
            </Areas.Policy>
            <Areas.Links
              as={LinksStyled}
              justifyMd={'end'}
              justifyContent={'center'}
              justifyContentMd={'start'}
              justifyContentLg={'end'}
              marginBottom={'150px'}
              marginBottomLg={0}
              gap={'30px'}
              flex
            >
              <Social />
            </Areas.Links>
          </>
        );
      }}
    </Composition>
  );
};
