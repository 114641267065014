import Loadable from 'react-loadable';
import pMinDelay from 'p-min-delay';

import RouterLoading from 'modules/components/RouterLoading/RouterLoading';

export const Lazy = (loader: Promise<any>, loaderComponent: JSX.Element) => {
  return Loadable({
    loader: () => {
      return pMinDelay(loader, 1500);
    },
    loading: (loadingProps) => {
      return (
        <RouterLoading {...loadingProps} loaderComponent={loaderComponent} />
      );
    },
    // delay need only for showing Loader not content
    delay: 60,
    timeout: 10000,
  });
};
