import { FC, useState } from 'react';

import { StoredDrug } from 'stores/XSublimatio/XSublimatioStore';
import PH_DRUG from 'assets/images/PH_DRUG.jpg';
import SmartImage from 'modules/components/SmartImage';
import { useRootStore } from 'providers/RootStoreProvider';

import PrimaryButton from '../Button/PrimaryButton';
import { CompositionOverlay } from '../CompositionOverlay/CompositionOverlay';
import { Icon } from '../Icon';
import {
  Border,
  ButtonWrapper,
  CreateNftCardStyled,
  DrugMakeupStyled,
  ImageWrapperStyled,
  InfoWrapperStyled,
  SoldOutTextStyled,
  TextStyled,
  TitleSectionStyled,
  InfoSectionStyled,
} from './styles';

const StateIcon: FC<{ canCreate: boolean }> = ({ canCreate }) => (
  <>
    {!canCreate ? (
      <Icon name="cross" height="24" width="24" fill="#FF13AF" />
    ) : (
      <Icon name="check" height="24" width="24" fill="#50FF13" />
    )}
  </>
);

interface ICreateNftCard {
  drug: StoredDrug;
  onOpenModal?: () => void;
}

export const CreateNftCard = (props: ICreateNftCard): JSX.Element => {
  const { drug, onOpenModal } = props;

  const { xsublimatioController } = useRootStore();

  const [isCompositionOpen, setCompositionOpen] = useState(false);

  const handleToggleShowComposition = () => {
    setCompositionOpen(!isCompositionOpen);
  };

  const canCreate = drug.brewPossibility.canBrew && !drug.isSoldOut;
  const isSoldOut = drug.isSoldOut;
  // const hasInsufficientMolecules = !drug.brewPossibility.canBrew;

  const getBtnText = () => {
    if (isSoldOut) {
      return 'Sold Out';
    }

    if (canCreate) {
      return 'Create';
    }

    return 'Insufficient Molecules';
  };

  const handleOpenModal = () => {
    if (onOpenModal) {
      onOpenModal();
    }
    xsublimatioController.setCreateDrug(drug);
  };

  const parsedDrugName = drug.name.toLowerCase().replaceAll(' ', '-');
  const imageUrl = `https://res.cloudinary.com/faction/image/upload/v1655093371/faction/xsublimatio/placeholders/${parsedDrugName}.png`;

  return (
    <>
      <CreateNftCardStyled>
        <ImageWrapperStyled>
          <SmartImage
            url={imageUrl}
            placeholder={PH_DRUG}
            width={300}
            height={300}
          />
          {isSoldOut && <SoldOutTextStyled>SOLD OUT</SoldOutTextStyled>}
        </ImageWrapperStyled>
        <TitleSectionStyled>
          <div>{drug.name}</div>
          <StateIcon canCreate={canCreate} />
        </TitleSectionStyled>
        <ButtonWrapper>
          <PrimaryButton
            size="l"
            disabled={!canCreate}
            onClick={handleOpenModal}
          >
            {getBtnText()}
          </PrimaryButton>
        </ButtonWrapper>
        <Border />
        {isCompositionOpen && (
          <CompositionOverlay composition={drug.composition} />
        )}

        <InfoSectionStyled>
          <DrugMakeupStyled onClick={handleToggleShowComposition}>
            <TextStyled>
              {isCompositionOpen ? 'Hide Composition' : 'Show Composition'}
              <InfoWrapperStyled>
                <Icon name="info-circle-filled" />
              </InfoWrapperStyled>
            </TextStyled>
          </DrugMakeupStyled>
        </InfoSectionStyled>
      </CreateNftCardStyled>
    </>
  );
};
