import { FC } from 'react';

import styled, { css } from 'styled-components/macro';

import Button, { ButtonProps } from './Button';

const OutlinedButtonStyled = styled(Button)<{ isActive?: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary[9]};

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.primary[1]};
    color: ${theme.colors.primary[1]};

    &:disabled {
      color: ${theme.colors.primary[21]};
      border-color: ${theme.colors.primary[21]};
    }

    &:active {
      background: ${theme.colors.primary[1]};
      color: ${theme.colors.primary[9]};
    }

    &:hover {
      color: ${theme.colors.primary[1]};
      background: ${theme.colors.primary[6]};
    }

    &:focus {
      color: ${theme.colors.primary[1]};
      background: ${theme.colors.primary[6]};
    }
  `}

  ${({ theme, isActive }) =>
    isActive &&
    css`
      color: ${theme.colors.primary[9]};
      background: ${theme.colors.primary[1]};

      &:active,
      &:focus,
      &:hover {
        color: ${theme.colors.primary[9]};
        background: ${theme.colors.primary[1]};
      }
    `}
`;

const OutlinedButton: FC<ButtonProps> = (props) => {
  const { children, isActive, ...rest } = props;
  return (
    <OutlinedButtonStyled isActive={isActive} {...rest}>
      {children}
    </OutlinedButtonStyled>
  );
};

export default OutlinedButton;
