import {
  DRUGS,
  getTokenFromId,
  MOLECULES,
  Token,
} from '@faction-nfts/xsublimatio-smart-contracts';
import {
  Transfer,
  XSublimatio,
} from '@faction-nfts/xsublimatio-smart-contracts/dist/esm/web3/XSublimatio';
import { constants } from 'ethers';
import { makeAutoObservable, observable, toJS } from 'mobx';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { XSublimatio__factory } from '@faction-nfts/xsublimatio-smart-contracts/dist/esm/ethers';

import projectContracts from 'contracts/projects';
import { DEFAULT_CHAIN_ID } from 'constants/chains';
import { INFURA_NETWORK_SOCKETS } from 'constants/chainInfo';
import type { RootStore } from 'stores/RootStore';
import { getProject } from 'api/sdk';
import { ProjectTypeType } from 'api/generated/graphql';

import { returnStatement } from '.pnpm/@babel+types@7.18.8/node_modules/@babel/types';

const bucketUrl = process.env.REACT_APP_XSUBLIMATIO_BUCKET as string;
const contractAddress = projectContracts.xsublimatio[DEFAULT_CHAIN_ID]
  ?.address as string;
const defaultNode = INFURA_NETWORK_SOCKETS[DEFAULT_CHAIN_ID];
const websocketProvider = new Web3.providers.WebsocketProvider(defaultNode);
const web3 = new Web3(websocketProvider);

export class ArtworkMetadata {
  public attributes: any[];

  public description: string;

  public name: string;

  public background_color: string;

  public image: string;

  public animation_url: string;

  public artist: string;

  public external_url: string;

  constructor(
    attributes: any[],
    description: string,
    name: string,
    background_color: string,
    image: string,
    animation_url: string,
    artist: string,
    external_url: string
  ) {
    this.attributes = attributes;
    this.description = description;
    this.name = name;
    this.background_color = background_color;
    this.image = image;
    this.animation_url = animation_url;
    this.artist = artist;
    this.external_url = external_url;
    makeAutoObservable(this);
  }
}
export class ArtworkToken {
  public id: string;

  public globalType: number;

  public type: number;

  public name: string;

  public category: 'molecule' | 'drug';

  public specialWaterIndex?: number;

  public hue: number;

  public saturation: number;

  public brightness: number;

  public seed: number;

  public deformationType: any;

  public stripesColorShiftType: any;

  public stripesAmountType: any;

  public blobType: any;

  public paletteType: any;

  public moleculeLightingType?: any;

  public moleculeIntegrityType?: any;

  public metadata: ArtworkMetadata;

  constructor(
    id: string,
    globalType: number,
    type: number,
    name: string,
    category: 'molecule' | 'drug',
    hue: number,
    saturation: number,
    brightness: number,
    seed: number,
    deformationType: any,
    stripesColorShiftType: any,
    stripesAmountType: any,
    blobType: any,
    paletteType: any,
    metadata: ArtworkMetadata,
    specialWaterIndex?: number,
    moleculeLightingType?: any,
    moleculeIntegrityType?: any
  ) {
    this.id = id;
    this.globalType = globalType;
    this.type = type;
    this.name = name;
    this.category = category;
    this.specialWaterIndex = specialWaterIndex;
    this.hue = hue;
    this.saturation = saturation;
    this.brightness = brightness;
    this.seed = seed;
    this.deformationType = deformationType;
    this.stripesColorShiftType = stripesColorShiftType;
    this.stripesAmountType = stripesAmountType;
    this.blobType = blobType;
    this.paletteType = paletteType;
    this.moleculeLightingType = moleculeLightingType;
    this.moleculeIntegrityType = moleculeIntegrityType;
    this.metadata = new ArtworkMetadata(
      metadata.attributes,
      metadata.description,
      metadata.name,
      metadata.background_color,
      metadata.image,
      metadata.animation_url,
      metadata.artist,
      metadata.external_url
    );

    makeAutoObservable(this);
  }
}
export class Artwork {
  public owner: string;

  public data: ArtworkToken;

  public tokenId: string;

  public artist: {
    name: string;
  };

  public thumbnail: string;

  public placeholder: string;

  constructor(
    owner: string,
    data: ArtworkToken,
    tokenId: string,
    artist: { name: string }
  ) {
    this.owner = owner;
    this.data = new ArtworkToken(
      data.id,
      data.globalType,
      data.type,
      data.name,
      data.category,
      data.hue,
      data.saturation,
      data.brightness,
      data.seed,
      data.deformationType,
      data.stripesColorShiftType,
      data.stripesAmountType,
      data.blobType,
      data.paletteType,
      data.metadata,
      data.specialWaterIndex,
      data.moleculeLightingType,
      data.moleculeIntegrityType
    );
    this.tokenId = tokenId;
    this.artist = artist;
    this.thumbnail = `https://xsublimation.s3.amazonaws.com/image/300x300/${tokenId}.webp`;
    this.placeholder =
      this.data.category === 'molecule'
        ? 'https://res.cloudinary.com/faction/image/upload/v1652413962/faction/xsublimatio/placeholders/PH_MOL_baxnpo.jpg'
        : 'https://res.cloudinary.com/faction/image/upload/v1652413962/faction/xsublimatio/placeholders/PH_DRUG_bdsvaw.jpg';

    makeAutoObservable(this);
  }
}

export type StoredDrug = XSublimatioStore['drugs'][number];
export type StoredMolecule = XSublimatioStore['molecules'][number];
export type StoredArtwork = Artwork;

export type ArtworksFilterAttributes = {
  [key: string]: (number | string)[];
};

export class XSublimatioStore {
  private rootStore: RootStore;

  private contract: XSublimatio | null;

  public artworks = observable.map<string, Artwork>();

  public searchedArtworkName: string;

  public artworkAttributes: ArtworksFilterAttributes;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.contract = contractAddress
      ? (new web3.eth.Contract(
          XSublimatio__factory.abi as AbiItem[],
          contractAddress
        ) as unknown as XSublimatio)
      : null;

    this.searchedArtworkName = '';
    this.artworkAttributes = {
      Category: [],
      Name: [],
      Hue: [],
      Saturation: [],
      Brightness: [],
      Seed: [],
      Deformation: [],
      'Stripes Color Shift': [],
      'Stripes Amount': [],
      Blob: [],
      Palette: [],
      'Molecule Lighting': [],
      'Molecule Integrity': [],
    };

    makeAutoObservable(this);
  }

  public init = async () => {
    await this.processExistingArtworks();
    this.subscribeToFutureArtworks();
  };

  get ownedArtworks() {
    const artworks = Array.from(this.artworks.values());
    const account = this.rootStore.web3Store.address;

    return artworks.filter(
      (i) => i.owner.toLowerCase() === account?.toLowerCase()
    );
  }

  get ownedMolecules() {
    const artworks = this.ownedArtworks;
    const molecules = artworks.filter(
      (artwork) => artwork.data.category === 'molecule'
    );

    return molecules.reverse();
  }

  public get filteredOwnedMolecules() {
    return this.ownedMolecules.filter((item) =>
      item.data.name
        .toLowerCase()
        .includes(this.searchedArtworkName.toLowerCase())
    );
  }

  public get filteredOwnedDrugs() {
    return this.ownedDrugs.filter((item) =>
      item.data.name
        .toLowerCase()
        .includes(this.searchedArtworkName.toLowerCase())
    );
  }

  get ownedDrugs() {
    const artworks = this.ownedArtworks;
    const drugs = artworks.filter(
      (artwork) => artwork.data.category === 'drug'
    );

    return drugs.reverse();
  }

  get ownedMoleculeCount() {
    return this.ownedMolecules.length;
  }

  get filteredOwnedMoleculesCount() {
    return this.filteredOwnedMolecules.length;
  }

  get filteredOwnedDrugsCount() {
    return this.filteredOwnedDrugs.length;
  }

  get ownedDrugCount() {
    return this.ownedDrugs.length;
  }

  get ownedArtworkCount() {
    return this.ownedArtworks.length;
  }

  private processEvent = (event: Transfer) => {
    const { from, to, tokenId } = event.returnValues;

    if (
      from === constants.AddressZero ||
      (from !== constants.AddressZero && to !== constants.AddressZero)
    ) {
      this.setArtwork(tokenId, to);
    }

    if (to === constants.AddressZero) {
      this.artworks.delete(tokenId);
    }
  };

  private setArtwork = (tokenId: string, owner: string) => {
    const data = getTokenFromId(
      tokenId,
      'https://res.cloudinary.com/faction/image/upload/faction/xsublimatio/image',
      'https://res.cloudinary.com/faction/video/upload/faction/xsublimatio/video',
      'png',
      'webm'
    );
    this.artworks.set(
      tokenId,
      new Artwork(owner, data, tokenId, {
        name: 'Pierre Pauze',
      })
    );
  };

  private subscribeToFutureArtworks = async () => {
    if (!this.contract) return;

    try {
      const currentBlockNumber = await web3.eth.getBlockNumber();
      const fromBlock = currentBlockNumber - 1000;

      this.contract.events.Transfer({ fromBlock }, (error, transfer) => {
        if (error) {
          throw error;
        }
        if (!!transfer) {
          this.processEvent(transfer);
        }
      });
    } catch (error) {
      throw error;
    }
  };

  private processExistingArtworks = async () => {
    if (!this.contract) return;

    try {
      const project = await getProject(ProjectTypeType.Xsublimatio);
      if (!project) return;

      project?.artworks?.forEach((artwork) => {
        this.setArtwork(
          artwork.tokenId as string,
          artwork.owner?.address as string
        );
      });
    } catch (error) {
      throw error;
    }
  };

  get moleculeCount() {
    const artworks = Array.from(this.artworks.values());
    const molecules = artworks.filter(
      (artwork) => artwork.data.category === 'molecule'
    );

    return molecules.length;
  }

  get drugCount() {
    const artworks = Array.from(this.artworks.values());
    const drugs = artworks.filter(
      (artwork) => artwork.data.category === 'drug'
    );

    return drugs.length;
  }

  get artworkCount() {
    return Array.from(this.artworks.keys()).length;
  }

  get getArtworks() {
    return Array.from(this.artworks.values());
  }

  public get artworksNamesArr() {
    const namesArr = this.getArtworks.map((artwork) => artwork.data.name);
    return [...new Set(namesArr)];
  }

  public get filteredArtworks() {
    return this.getArtworks.filter((item) => {
      return item.data.name
        .toLowerCase()
        .includes(this.searchedArtworkName.toLowerCase());
    });
  }

  public get filteredArtworksByAttributes() {
    const ruleEntries = Object.entries(this.artworkAttributes);

    return this.getArtworks.filter((artwork) => {
      return ruleEntries.every(([attribute, values]) => {
        if (values.length === 0) {
          return true;
        }

        const attr = artwork.data.metadata.attributes.find(
          (item) => item.trait_type === attribute
        );

        if (!attr) {
          return false;
        }

        if (typeof values[0] === 'string') {
          return values.includes(attr.value.toLowerCase());
        }

        return attr.value >= values[0] && attr.value <= values[1];
      });
    });
  }

  get moleculeArtworks() {
    const artworks = Array.from(this.artworks.values());
    const drugs = artworks.filter(
      (artwork) => artwork.data.category === 'molecule'
    );

    return drugs;
  }

  get drugArtworks() {
    const artworks = Array.from(this.artworks.values());
    const molecules = artworks.filter(
      (artwork) => artwork.data.category === 'drug'
    );

    return molecules;
  }

  get drugs() {
    const ownedTokens = this.ownedArtworks.map((artwork) => artwork.data);

    return DRUGS.map((drug, drugId) => {
      const totalSupply = Array.from(
        this.rootStore.xsublimatioStore.artworks.values()
      ).filter(
        (val) => val.data.name === drug.name && val.data.category === 'drug'
      ).length;

      const brewPossibility = drug.getBrewPossibility(ownedTokens);
      const composition = drug.recipe.map((recipe, index) => {
        const isAvailable = brewPossibility.recipeMolecules[index].length > 0;
        const name = recipe.name;

        return {
          name,
          isAvailable,
        };
      });

      return {
        ...drug,
        composition,
        brewPossibility,
        isSoldOut: totalSupply === drug.maxSupply,
        drugId,
        totalSupply,
        maxSupply: drug.maxSupply,
      };
    });
  }

  get molecules() {
    return MOLECULES.map((molecule) => {
      const totalSupply = Array.from(
        this.rootStore.xsublimatioStore.artworks.values()
      ).filter(
        (val) =>
          val.data.name === molecule.name && val.data.category === 'molecule'
      ).length;

      return {
        ...molecule,
        name: molecule.name,
        totalSupply,
        maxSupply: molecule.maxSupply,
      };
    });
  }

  get filteredMolecules() {
    return this.molecules.filter((item) =>
      item.name.toLowerCase().includes(this.searchedArtworkName.toLowerCase())
    );
  }

  get filteredDrugs() {
    return this.drugs.filter((item) =>
      item.name.toLowerCase().includes(this.searchedArtworkName.toLowerCase())
    );
  }

  public setSearchedArtworkName = (artworkName: string): void => {
    this.searchedArtworkName = artworkName;
  };

  public setArtworksAttributes = (
    artworkAttributes: ArtworksFilterAttributes
  ): void => {
    this.artworkAttributes = artworkAttributes;
  };

  get getArtworksAttributes() {
    return this.artworkAttributes;
  }
}
